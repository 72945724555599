/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
import { LayoutContextType, LocationType } from 'common/types/CheckinData.type';
import { createContext } from 'react';
export const InitialSettingsData: LayoutContextType = {
  company_logo_base64: '',
  print_company_logo_on_badge: false,
  language: '',
  visitorClaims: [],
  isPrintBadge: false,
  isCheckInOnly: 0,
  hostDepartmentSelector: 0,
  countryCodeAndFlag: {
    countryCode: '',
    countryFlag: '',
  },
  location: {} as LocationType,
  isQrCode: false,
  theme: {
    colors: {
      primary: '#3277FF',
      primaryLight: '#CAD4E5',
      primaryMedium: '#2A509B',
      primaryStrong: '#72A1FF',
      secondary: '#ffffff',
      secondaryStronger: '#F5F7FA',
      tertiary: '#7fc9f340',
      tertiaryStrong: '#000000',
    },
  },
  logoLink: '',
  barcodePrefix: '',
  clientName: '',
};

export const LayoutContext = createContext({
  loading: false,
  countryCodeAndFlag: {
    countryCode: '',
    countryFlag: '',
  },
  settings: InitialSettingsData,
  loadingSettings: false,
  initLoading: () => {},
  finishLoading: () => {},
  fetchSettings: async (_resetSettings?: boolean) => {},
  fetchedSettings: false,
});
