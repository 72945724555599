export const selectComponentStyle: {
  [key: string]: (
    base: { [key: string]: string },
    state?: { [key: string]: boolean }
  ) => void;
} = {
  placeholder: (base) => ({
    ...base,
    fontSize: '1em',
    color: '#A7A7A7',
    fontWeight: 400,
    backgroundColor: 'transparent',
  }),

  dropdownIndicator: (base, state) => ({
    ...base,
    fontSize: '16px',
    paddingRight: '0',
    color: '#000000',
    '&:hover': {
      color: 'black',
    },
    svg: {
      width: 15,
      color: state?.isDisabled ? 'grey' : '#000000',
    },
  }),

  // Control (input)
  control: (base, state) => ({
    ...base,
    border: state?.isFocused
      ? '2px solid #e5e7eb !important'
      : '2px solid #e5e7eb !important',
    boxShadow: state?.isFocused
      ? '0 0 0 2px #cbd5e1 !important'
      : '0 0 0 2px white !important',
    '&:hover': {
      border: state?.isFocused ? 0 : 0,
    },
    padding: '3px 0',
    margin: '0',
    fontSize: '16px',
    marginTop: '5px',
    flexDirection: 'row-reverse',
    color: 'green',
  }),

  // Option
  option: (base, state) => ({
    ...base,
    fontSize: '16px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '18px',
    backgroundColor: state?.isFocused ? '#EBF4FE' : null,
    color: '#003060',
  }),

  singleValue: (base) => ({
    ...base,
    color: '#000000',
    backgroundColor: 'transparent',
    fontSize: '20px',
    fontWeight: 400,
  }),

  // Menu
  menuPortal: (base) => ({
    ...base,
    zIndex: 50,
  }),

  menu: (base) => ({
    ...base,
    width: '100%',
    border: '1px solid #DDE4EE',
    borderRadius: '5px',
    padding: 0,
    margin: 0,

    backgroundColor: '#FAFCFE',
  }),

  menuList: (base) => ({
    ...base,
    maxHeight: '150px',
  }),
};

export const nonSearchableStyle: {
  [key: string]: (
    base: { [key: string]: string },
    state?: { [key: string]: boolean }
  ) => void;
} = {
  control: (base, state) => ({
    ...base,
    border: state?.isFocused
      ? '1px solid #2563eb !important'
      : '1px solid #A7A7A7 !important',
    boxShadow: state?.isFocused ? '0 0 0 2px #cbd5e1 !important' : '',
    '&:hover': {
      border: state?.isFocused ? 0 : 0,
    },
    backgroundColor: '#fff',
    padding: '0px 3px',
    margin: '0',
    borderRadius: '8px',
    height: '59px',
    fontSize: '20px',
    color: '#A7A7A7',
  }),
};
