import React, { useContext } from 'react';
import { get as _get } from 'lodash';
import { LayoutContext } from 'context/LayoutContext';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  round?: boolean;
  color?: 'white' | 'blue';
  shadow?: boolean;
  readOnly?: boolean;
  type?: string;
  flag?: string;
  prepend?: string;
  wrapperClassName?: string;
  className?: string;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  label?: string;
  required?: boolean;
  append?: React.ReactNode;
  disabled?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      type = 'text',
      color = 'white',
      shadow = false,
      round = false,
      readOnly = false,
      className = '',
      wrapperClassName = '',
      label,
      size = 'lg',
      flag = '',
      name,
      errors,
      required = false,
      append,
      disabled = false,
      prepend,
      ...props
    },
    ref
  ) => {
    const error = _get(errors, name || '', null);
    const {
      settings: { theme },
    } = useContext(LayoutContext);

    return (
      <div
        style={{ color: theme.colors.primary }}
        className={`relative ${
          size === 'lg' ? 'max-w-2xl' : 'w-fit'
        } text-tertiary-stronger flex flex-col ${wrapperClassName}`}
      >
        {label && (
          <label
            className="pt-1 text-tertiary-stronger text-xl"
            style={{ color: theme.colors.tertiaryStrong }}
          >
            {label} {required ? <span className="text-black">{' *'}</span> : ''}
          </label>
        )}
        <div
          className={`border-[#A7A7A7] bg-white focus-within:border-[#2563eb]  relative flex justify-center items-center rounded-lg  outline-none border text-lg font-semibold  mt-1 ${className} ${
            prepend ? 'pl-[0.9rem] pb-0.5' : ''
          }`}
        >
          {flag && (
            <div className="flex bg-white justify-center overflow-hidden text-center rounded-full aspect-square -ml-2 mr-2">
              <img src={flag} alt={flag} className="transform scale-150 w-9" />
            </div>
          )}
          {prepend && (
            <p className="flex justify-center text-center mr-1">{prepend}</p>
          )}
          <input
            ref={ref}
            type={type}
            readOnly={readOnly}
            placeholder={placeholder}
            name={name}
            className="flex-1 focus:border-none text-xl focus:ring-0 pl-2 bg-white rounded-lg border-none text-tertiary-stronger py-[1.1rem]  ml-0 font-normal leading-5  disabled:opacity-50 focus:outline-none focus:shadow-none"
            disabled={disabled}
            {...props}
          />

          {append ? (
            <div
              className={`pr-1.5 h-full bg-white ${
                disabled && 'pointer-events-none opacity-50'
              }`}
            >
              {append}
            </div>
          ) : null}
        </div>
        <label
          className={`text-red-700 pt-1 mt-2 text-[14px] leading-[16px] absolute bottom-0 transform translate-y-full font-bold ${
            error && error.message ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {error?.message}
        </label>
      </div>
    );
  }
);

export default Input;
