import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from 'react-query';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Types
import { PhoneNumberDataType } from 'common/types/CheckinData.type';

// Services
import checkoutService from 'services/checkout.service';

// Context
import { CheckinContext } from 'context/CheckinContext';

// Utils
import { ApiError } from 'utils/api';

// Components
import ContentLayout from 'common/layouts/ContentLayout';
import { KeyboardInput } from 'containers/KeyboardInput';
import { KeyboardLayout } from 'common/components/Input/KeyboardWrapper';
import checkinServices from 'services/checkin.service';
import { LayoutContext } from 'context/LayoutContext';

const Checkout = () => {
  // Schema
  const getSchema = () =>
    yup.object().shape({
      countryCode: yup
        .string()
        .required('translate_:errorsFromYup.required.countryCode'),
      phoneNumber: yup
        .string()
        .test(
          'phoneNumberTest',
          'translate_:errorsFromYup.notValid.phoneNumber',
          async function (value) {
            let phoneNumberWithCountryCode = `+${countryCodeAndFlag.countryCode}${value}`;
            if (value?.includes('+')) {
              phoneNumberWithCountryCode = value;
            }
            try {
              await checkinServices.getPhoneNumberData(
                phoneNumberWithCountryCode
              );
              return true;
            } catch {
              return false;
            }
          }
        ),
    });
  // Hooks
  const { t } = useTranslation();
  const {
    data: { phoneNumber },
  } = useContext(CheckinContext);
  const { countryCodeAndFlag } = useContext(LayoutContext);
  const navigate = useNavigate();

  // Form
  const {
    control,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<PhoneNumberDataType>({
    mode: 'onChange',
    resolver: yupResolver(getSchema()),
    defaultValues: phoneNumber || countryCodeAndFlag.countryCode,
  });

  // Queries
  const { mutate: checkOut, isLoading: loading } = useMutation(
    'checkout',
    (PhoneNumberData: PhoneNumberDataType) =>
      checkoutService.checkout(PhoneNumberData),
    {
      onSuccess: () => {
        navigate('/thank-you/checkout');
      },
      onError: (error) => {
        const err = error as ApiError;
        toast.error(`${err?.message}`);
      },
    }
  );

  const handleOnSubmit = (data: PhoneNumberDataType) => {
    checkOut({
      ...data,
      countryCode: data.phoneNumber.includes('+')
        ? ''
        : `+${countryCodeAndFlag.countryCode}`,
    });
  };

  return (
    <form
      className="w-full h-full"
      onSubmit={handleSubmit(handleOnSubmit)}
      autoComplete="off"
    >
      <ContentLayout
        isValid={isValid}
        loading={loading}
        previous={() => navigate('/')}
        hasPreviousButton={true}
        submitButtonText={t('button.checkOut')}
        title={`${t('phoneNumber.enterPhoneNumber')} ${t('common.checkout')}`}
        childrenClassName="max-w-2xl ml-auto mr-auto"
      >
        <KeyboardInput
          control={control}
          hideErrorOnEmptyValue
          disableDoneButton={false}
          size="sm"
          isNumericKeyboard={true}
          {...register('phoneNumber')}
          keyboardLayout={KeyboardLayout.NUMERIC}
          label={t('phoneNumber.title') || 'Phone Number'}
          placeholder=""
          required
        />
      </ContentLayout>
    </form>
  );
};

export default Checkout;
