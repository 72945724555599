import React from 'react';

import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Utils
import { getLanguagePrefix } from 'utils/helpers';

// Types
import { AnswerType, QuestionType } from 'common/types/CheckinData.type';

interface RadioGroupButtonsProps {
  questions: QuestionType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  errors?: string[];
  selectedOptions: number[];
  handleOptionChange: (questionIndex: number, optionIndex: number) => void;
}

const RadioGroupButtons: React.FC<RadioGroupButtonsProps> = ({
  questions,
  control,
  errors = '',
  selectedOptions,
  handleOptionChange,
}) => {
  const { i18n } = useTranslation();
  const questionLangSelected = getLanguagePrefix(i18n.language, 'questionText');
  const answerLangSelected = getLanguagePrefix(i18n.language, 'optionText');

  return (
    <>
      {questions.map((question, index) => (
        <div key={index}>
          <p className="text-3xl font-bold">
            {`${question[questionLangSelected as keyof QuestionType]}?`}{' '}
            {question.isBlocker ? '*' : ''}
          </p>
          {question.answerOptions.map((option, optionIndex) => (
            <Controller
              key={optionIndex}
              name={`question_${index}_selectedOption`} // Unique name for each question's group
              control={control}
              render={({ field }) => (
                <div>
                  <label className="text-2xl px-2">
                    <input
                      type="radio"
                      value={optionIndex}
                      checked={selectedOptions[index] === optionIndex}
                      className="w-6 h-6"
                      onChange={() => {
                        handleOptionChange(index, optionIndex);
                        field.onChange(optionIndex);
                      }}
                    />
                    {option[answerLangSelected as keyof AnswerType]}
                  </label>
                </div>
              )}
            />
          ))}
          {errors[index] && <p className="text-red-500">{errors[index]}</p>}
        </div>
      ))}
    </>
  );
};

export default RadioGroupButtons;
