export const keyboardLayoutOption = {
  numeric: {
    default: ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 + {Done}'],
  },
  qwerty: {
    default: [
      'q w e r t y u i o p {bksp}',
      '{shift} a s d f g h j k l',
      '{123} z x c v b n m , . {Done}',
      '{space}',
    ],
    shift: [
      'Q W E R T Y U I O P {bksp}',
      '{shift} A S D F G H J K L',
      'Z X C V B N M , . {Done}',
      '{space}',
    ],
    numeric: [
      '1 2 3 4 5 6 7 8 9 0 {bksp}',
      '_ % + = / ! . , -',
      '{abc} @ # & ^ § ( ) ; : {Done}',
      '{space}',
    ],
  },
  norwegianKeyboard: {
    default: [
      // '§ 1 2 3 4 5 6 7 8 9 0 + ´ {bksp}',
      'q w e r t y u i o p å ¨ {bksp}',
      // eslint-disable-next-line quotes
      "{shift} a s d f g h j k l ø æ '",
      '{123} < z x c v b n m , . - {Done}',
      '{space}',
    ],
    shift: [
      // '° ! " # $ % & / ( ) = ? ` {bksp}',
      'Q W E R T Y U I O P Å {bksp}',
      '{shift} A S D F G H J K L Ø Æ *',
      '> Z X C V B N M ; : _ {Done}',
      '{space}',
    ],
    numeric: [
      '1 2 3 4 5 6 7 8 9 0 {bksp}',
      '_ % + = / ! . , -',
      '{abc} @ # & ^ § ( ) ; : {Done}',
      '{space}',
    ],
  },
};
