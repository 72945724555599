import { useTranslation } from 'react-i18next';
// Component
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-right-thiner.svg';
import { useContext } from 'react';
import { LayoutContext } from 'context/LayoutContext';

interface Props {
  timeSlot: string;
  host: string;
  hostCompany: string;
  location: string;
  onClick: () => void;
  isClicked: boolean;
}
const Invite = ({
  timeSlot,
  host,
  hostCompany,
  location,
  onClick,
  isClicked,
}: Props) => {
  // Hooks
  const { t } = useTranslation();
  const {
    settings: { theme },
  } = useContext(LayoutContext);
  // Declaration
  const formatedDate = Date.parse(timeSlot as string);
  const hourAndMinute = `${new Date(formatedDate).getHours()}:${new Date(
    formatedDate
  ).getMinutes()}`;

  return (
    <div
      style={{ borderColor: isClicked ? theme.colors.primary : '' }}
      className={`max-w-2xl w-full h-[7rem] mt-7 bg-white opacity-70 rounded-2xl cursor-pointer ${
        isClicked ? 'border-2 border-primary' : ''
      }`}
      onClick={onClick}
    >
      <div className="flex justify-between px-5 py-3">
        <div className="flex flex-col gap-2 text-tertiary-stronger">
          <p>{t('phoneNumber.timeSlot')}</p>
          <p>{hourAndMinute}</p>
        </div>
        <div className="flex flex-col gap-2 text-tertiary-stronger">
          <p>{t('phoneNumber.host')}</p>
          <p>{host}</p>
          <p>{hostCompany}</p>
        </div>
        <div className="flex flex-col gap-2 text-tertiary-stronger">
          <p>{t('phoneNumber.location')}</p>
          <abbr
            className="w-[5rem] truncate overflow-hidden no-underline"
            title={location}
          >
            {location}
          </abbr>
          <p>A-123-321</p>
        </div>
        <span className="w-[2.2rem] flex cursor-pointer">
          <ArrowIcon fill={theme.colors.primary} />
        </span>
      </div>
    </div>
  );
};

export default Invite;
