import {
  AnswerType,
  CheckinApiDataType,
  CheckinDataType,
  QuestionType,
  RequirementDataType,
  RequirementsApiDataType,
  SettingsType,
  VisitorsClaimsDataType,
} from 'common/types/CheckinData.type';
import BaseService from './base.service';
class CheckinServices extends BaseService {
  getVisitorClaims(data: RequirementDataType[]): RequirementsApiDataType[] {
    const items = data.map((item): RequirementsApiDataType => {
      return {
        claim_text: item.claimText,
        claim_text_en: item.claimTextEn,
        claim_text_sw: item.claimTextSw,
        claim_text_da: item.claimTextDa,
        is_blocker: item.isBlocker,
        is_accepted: item.checked,
      };
    });
    return items;
  }
  flatenCheckinData(data: CheckinDataType): CheckinApiDataType {
    let employeeOrDepartmentId = data.visitInformation.employee;
    const language = localStorage.getItem('i18nextLng');
    if (data.visitInformation.host !== 'employee') {
      employeeOrDepartmentId = data.visitInformation.department;
    }
    const hseQuestionToSnakeCase = data?.hseQuestions?.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: QuestionType) => {
        return {
          question_text: item.questionText,
          question_text_en: item.questionTextEn,
          question_text_sv: item.questionTextSv,
          question_text_da: item.questionTextDa,
          is_blocker: item.isBlocker,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          answer_options: item.answerOptions.map((item: AnswerType) => {
            return {
              option_text: item.optionText,
              option_text_da: item.optionTextDa,
              option_text_en: item.optionTextEn,
              option_text_sv: item.optionTextSv,
              is_correct: item.isCorrect,
              is_selected: item.isSelected,
            };
          }),
        };
      }
    );
    const result = {
      employee_id: employeeOrDepartmentId,
      phone: data.phoneNumber.countryCode + data.phoneNumber.phoneNumber,
      first_name: data.personalInformation.firstName,
      last_name: data.personalInformation.lastName,
      company: data.personalInformation.companyName,
      visit_purpose: data.visitInformation.visitPurpose || null,
      visitor_claims: this.getVisitorClaims(data.requirements),
      remember_me: data.personalInformation.rememberMe,
      selected_language: language,
      hse_questionnaire: hseQuestionToSnakeCase,
    };
    // eslint-disable-next-line no-console
    console.log('RESUL ON CHECKING', result.hse_questionnaire);
    // Checkin Data
    return result;
  }
  async fetchSettings(): Promise<SettingsType> {
    const res = await this.axios.get('/receptionist/settings');
    const data = res.data.data;
    const items = data.visitor_claims.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: any): VisitorsClaimsDataType => {
        return {
          claimText: item.claim_text,
          claimTextEn: item.claim_text_en,
          claimTextSw: item.claim_text_sw,
          claimTextDa: item.claim_text_da,
          isBlocker: item.is_blocker,
        };
      }
    );
    const hseQuestions = data?.hse_questionnaire?.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: any): QuestionType => {
        return {
          questionText: item.question_text,
          questionTextEn: item.question_text_en,
          questionTextDa: item.question_text_sv,
          questionTextSv: item.question_text_da,
          isBlocker: item.is_blocker,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          answerOptions: item.answer_options.map((item: any): AnswerType => {
            return {
              optionText: item.option_text,
              optionTextDa: item.option_text_da,
              optionTextEn: item.option_text_en,
              optionTextSv: item.option_text_sv,
              isCorrect: item.is_correct,
              isSelected: false,
            };
          }),
        };
      }
    );
    return {
      company_logo_base64: data.company_logo_base64 || '',
      print_company_logo_on_badge: data.print_company_logo_on_badge,
      logoLink: data.logo_link,
      hostDepartmentSelector: data.host_department_selector_setting,
      isCheckInOnly: data.is_check_in_only,
      location: {
        ...data.location,
        packageDelivery: {
          isActive: data.package_delivery.is_active as boolean,
          message: data.package_delivery.message as string,
          phone: data.package_delivery.phone as string,
        },
        clientId: data.client_id,
      },
      language: data.language,
      visitorClaims: items,
      isQrCode: !!data.is_qr_code,
      isPrintBadge: !!data.is_print_badge,
      barcodePrefix: data.barcode_prefix,
      clientName: data.client_name,
      backgroundImageLink: data.background_image_link,
      theme: data.theme,
      logoSize: data.logo_size,
      showTouchKeyboard: data.show_touch_keyboard,
      hseQuestionnaire: hseQuestions || null,
    };
  }
  async checkin(checkinData: CheckinDataType) {
    const data = this.flatenCheckinData(checkinData);
    const res = await this.axios.post('/receptionist/check-in', data);
    return res.data;
  }
  async notifyPackageDelivery() {
    const res = await this.axios.post('/receptionist/package-delivery');
    return res.data;
  }
  async getPhoneNumberData(phone?: string, companyId?: number | null) {
    const res = await this.axios.post('/receptionist/validate/phone', {
      phone,
      company_id: companyId,
    });
    return res.data;
  }
}
const checkinServices = new CheckinServices();
export default checkinServices;
