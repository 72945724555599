import { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';

// Context
import { LayoutContext } from 'context/LayoutContext';
import { CheckinContext } from 'context/CheckinContext';

// Layouts
import ContentLayout from 'common/layouts/ContentLayout';

// Types
import { QuestionType } from 'common/types/CheckinData.type';

// Components
import RadioGroupButtons from 'common/components/RadioButtonGroup/RadioGroupButtons';

export const HseQuestions = () => {
  const { t } = useTranslation();
  const {
    settings: { hseQuestionnaire },
  } = useContext(LayoutContext);
  const { incrementStep, setHseQuestions, data, decrementStep } =
    useContext(CheckinContext);
  const { hseQuestions } = data;

  const initialSelectedOptions = hseQuestions.map((question) =>
    question.answerOptions.findIndex((option) => option.isSelected)
  );

  // Form
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      questions: hseQuestions?.length
        ? hseQuestions
        : hseQuestionnaire?.map((item) => {
            const answerOptionsWithSelected = item.answerOptions.map(
              (option) => ({
                ...option,
                isSelected: false,
              })
            );
            return {
              ...item,
              answerOptions: answerOptionsWithSelected,
            };
          }),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'questions',
  });

  const [questions, setQuestions] = useState<QuestionType[]>(fields);
  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    hseQuestions?.length
      ? initialSelectedOptions
      : new Array(fields.length).fill(-1)
  );
  const [errors, setErrors] = useState<string[]>(
    new Array(fields.length).fill('')
  );

  const handleOptionChange = (questionIndex: number, optionIndex: number) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[questionIndex] = optionIndex;
    setSelectedOptions(newSelectedOptions);

    // Update isSelected property for answerOptions
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].answerOptions.forEach((option, index) => {
      option.isSelected = index === optionIndex;
    });
    setQuestions(updatedQuestions); // Update the state directly

    // Validate blocker question
    if (updatedQuestions[questionIndex].isBlocker) {
      const isCorrect =
        updatedQuestions[questionIndex].answerOptions[optionIndex].isCorrect;
      const errorMessage = isCorrect
        ? ''
        : t('errorsFromYup.required.correctAnswer');
      const newErrors = [...errors];
      newErrors[questionIndex] = errorMessage;
      setErrors(newErrors);
    }
  };

  // Handlers
  const handleOnSubmit = async () => {
    await setHseQuestions(questions);
    await incrementStep();
  };

  // Generate the isRequired array based on isBlocker property
  const isRequired =
    hseQuestionnaire?.map((question) => question.isBlocker) || [];

  // Check if all questions are answered correctly
  const allAnsweredCorrectly =
    errors.every((error, index) => {
      const isBlocker = isRequired[index];

      // If the question is not a blocker, it's considered answered correctly
      if (!isBlocker) return true;

      // Check if the question is required and not filled out
      const isNotFilledOut = error === '' && selectedOptions[index] === -1;

      // If the question is required and not filled out, treat it as a blocker
      if (isNotFilledOut) return false;

      // Otherwise, check if the question is answered correctly
      return error === '';
    }) && selectedOptions.every((optionIndex) => optionIndex !== -1); // Ensure all options are selected

  return (
    <form
      className="w-full h-full"
      onSubmit={handleSubmit(handleOnSubmit)}
      autoComplete="off"
    >
      <ContentLayout
        submitButtonText={t('button.next')}
        isValid={allAnsweredCorrectly && isDirty}
        title={t('hse.title').toString()}
        hasPreviousButton
        previous={decrementStep}
        childrenClassName="gap-3 w-12/12 lg:8/12 xl:w-6/12 ml-auto mr-auto"
      >
        <RadioGroupButtons
          control={control}
          questions={fields}
          errors={errors}
          selectedOptions={selectedOptions}
          handleOptionChange={handleOptionChange}
        />
      </ContentLayout>
    </form>
  );
};
export default HseQuestions;
