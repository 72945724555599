import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// Context
import { LayoutContext } from 'context/LayoutContext';

export const NoCompanyAvailable = () => {
  // Hooks
  const { t } = useTranslation();
  // Context
  const {
    settings: { theme },
  } = useContext(LayoutContext);
  return (
    <div className="relative flex flex-col items-center justify-center w-full pt-8 pb-40">
      <div
        className="text-lg text-center text-primary"
        style={{ color: theme.colors.primary }}
      >
        <h1 className="text-2xl font-bold">{t('noCompanyAvailable.title')}</h1>
        <h2>{t('noCompanyAvailable.description')}</h2>
        <a
          href="/"
          className="text-xl font-bold underline"
          style={{ color: theme.colors.primaryMedium }}
        >
          {t('noCompanyAvailable.refresh')}
        </a>
        <span
          className="flex flex-col mt-10"
          style={{ color: theme.colors.primaryMedium }}
        >
          <Link to="/" className="text-xl font-bold underline ">
            {t('noCompanyAvailable.backToHomePage')}
          </Link>
        </span>
      </div>
    </div>
  );
};
