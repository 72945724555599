import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
// Context
import { LayoutContext } from 'context/LayoutContext';
// Components
import { ReactComponent as LogoIcon } from 'assets/svg/logo-icon.svg';
const FrontpageNav = () => {
  // Context
  const { settings } = useContext(LayoutContext);
  // State
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageParam = Math.random().toString(12).slice(2);
  const renderLogoImage = () => {
    if (settings?.logoLink)
      return (
        <div className="flex justify-start">
          <img
            src={`${settings.logoLink}?img=${imageParam}`}
            alt={settings.logoLink}
            className={`h-full w-full object-cover duration-300 max-h-20 max-w-sm ${
              imageLoaded ? 'opacity-1' : 'opacity-0'
            }`}
            onLoad={() => setImageLoaded(true)}
          />
        </div>
      );
    return <LogoIcon />;
  };
  return (
    <Link to="/" className="cursor-pointer">
      {renderLogoImage()}
    </Link>
  );
};
export default FrontpageNav;
