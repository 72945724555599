import { useContext, ReactElement, ReactNode } from 'react';
import { LayoutContext } from 'context/LayoutContext';
interface Props {
  className?: string;
  title: string;
  type?: 'submit' | 'button';
  round?: 'none' | 'lg' | 'md' | '4xl' | 'sm';
  size?: 'sm' | 'md' | 'lg';
  background?: 'none' | 'default' | 'black';
  color?: 'default' | 'none' | 'black';
  children?: ReactNode | ReactElement | string;
  full?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const COLOR_CLASSES = {
  black: 'text-black',
  default: 'text-white',
  none: '',
};
const ROUND_CLASSES = {
  none: '',
  sm: 'rounded-lg',
  lg: 'rounded-[1.3rem]',
  '4xl': 'rounded-[2rem]',
  md: 'rounded',
};

const SIZE_CLASSES = {
  // sm: 'py-5 px-[1.9rem',
  sm: 'pt-5 pr-[1.9rem] pb-5 pl-[1.9rem]',
  md: 'py-5 px-[1.9rem] font-medium',
  lg: 'py-10 px-[4.4rem] ',
};

const BACKGROUND_CLASSES = {
  default: 'bg-primary-strong',
  black: 'bg-black',
  none: '',
};

const Button = ({
  className,
  title,
  type = 'button',
  round = 'lg',
  children = null,
  full = false,
  background = 'default',
  color = 'default',
  size = 'sm',
  ...props
}: Props): ReactElement => {
  // Context
  const {
    settings: { theme },
  } = useContext(LayoutContext);

  return (
    <button
      style={{
        background: background === 'none' ? '' : theme.colors.primaryStrong,
      }}
      type={type}
      className={` 
        ${full ? 'w-full' : 'w-fit'}
        disabled:opacity-50 
        border-0 border-transparent items-center justify-center flex flex-row gap-1 transition-all duration-300
        ${SIZE_CLASSES[size]}
        ${ROUND_CLASSES[round]}
        ${BACKGROUND_CLASSES[background]}
        ${COLOR_CLASSES[color]}
        ${className ? className : ''}
      `}
      {...props}
    >
      {title && <span>{title}</span>}
      {children}
    </button>
  );
};
export default Button;
