import { LayoutContext } from 'context/LayoutContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectLanguage = () => {
  const {
    settings: { theme },
  } = useContext(LayoutContext);
  const { i18n } = useTranslation();

  interface LanguagesType {
    title: string;
    label: string;
    value: string;
    flag: string;
  }

  const languages: LanguagesType[] = [
    {
      title: 'English',
      label: 'ENG',
      value: 'en',
      flag: 'fi fi-gb',
    },
    {
      title: 'Norwegian',
      label: 'NO',
      value: 'no',
      flag: 'fi fi-no',
    },
    {
      title: 'Sweden',
      label: 'SE',
      value: 'sv',
      flag: 'fi fi-se',
    },
    {
      title: 'Denmark',
      label: 'DA',
      value: 'da',
      flag: 'fi fi-dk',
    },
  ];

  const handleChangeLanguage = (language: LanguagesType) => {
    i18n.changeLanguage(language.value);
  };

  return (
    <div className="flex flex-row justify-start h-full gap-4 max-h-20">
      {languages.map((lang) => {
        const selectedLanguage = lang.value === i18n.language;
        return (
          <button
            onClick={() => handleChangeLanguage(lang)}
            key={lang.title}
            className={` flex flex-row place-items-center px-4 rounded h-16  ${
              selectedLanguage ? 'border-2' : ''
            }`}
            style={{
              backgroundColor: theme.colors.secondaryStronger,
              borderColor: theme.colors.primaryMedium,
            }}
          >
            <div className="flex justify-center overflow-hidden text-center rounded-full aspect-square">
              <span className={`text-3xl transform scale-150 ${lang.flag}`} />
            </div>
          </button>
        );
      })}
    </div>
  );
};
