import { ReactElement, useContext } from 'react';
import { LayoutContext } from 'context/LayoutContext';

// Interfaces
export interface HeaderProps {
  title: string;
  isWhite?: boolean;
  className?: string;
  fontWeight?: 'thin' | 'light' | 'normal' | 'medium' | 'bold';
  textSize?: 'sm' | 'md' | 'lg' | '2xl' | '3xl' | '';
}

const TEXT_SIZE = {
  '': '',
  sm: 'text-sm',
  md: 'text-[1.1rem]',
  lg: 'text-[2rem]',
  '2xl': 'text-[2.4rem] leading-10',
  '3xl': 'text-[5rem] leading-relaxed',
};

const Header = ({
  fontWeight = 'normal',
  textSize = 'md',
  className = '',
  title,
  isWhite = false,
}: HeaderProps): ReactElement => {
  // Context
  const {
    settings: { theme },
  } = useContext(LayoutContext);

  return (
    <h3
      style={{ color: theme.colors.tertiaryStrong }}
      className={`z-10 
        font-${fontWeight}
        ${isWhite ? 'text-secondary' : 'text-tertiary-stronger'}
        ${TEXT_SIZE[textSize]}
        ${className}
      `}
    >
      {title}
    </h3>
  );
};

export default Header;
