import { FunctionComponent, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import Keyboard from 'react-simple-keyboard';

// Style
import 'react-simple-keyboard/build/css/index.css';
import './index.scss';

import { keyboardLayoutOption } from './keyboardLayoutOption';
import { useTranslation } from 'react-i18next';

// Enum
export enum KeyboardLayout {
  QWERTY = 'qwerty',
  NUMERIC = 'numeric',
  NORWEGIANKEYBOARD = 'norwegianKeyboard',
}

interface KeyboardWrapperProps {
  onChange?: (input: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyboardRef: any;
  value: string;
  isCheckout?: boolean;
  capitalizeOnSpace?: boolean;
  isDirty?: boolean;
  error: FieldError | undefined;
  onDoneKeyPressed: () => void;
  onBackspaceKeyPressed?: () => void;
  onCharacterPressed?: (newCharacter: string) => void;
  layout: KeyboardLayout;
  numericKeyboard?: boolean;
  disableDoneButton?: boolean;
  inputName: string;
}

const KeyboardWrapper: FunctionComponent<KeyboardWrapperProps> = ({
  onChange,
  keyboardRef,
  numericKeyboard,
  onBackspaceKeyPressed,
  onDoneKeyPressed,
  capitalizeOnSpace = false,
  layout,
  disableDoneButton = false,
  isCheckout = false,
  value,
  inputName,
  error = '',
  onCharacterPressed,
}) => {
  // State
  const [layoutName, setLayoutName] = useState('default');
  const { t } = useTranslation();

  // Handlers
  const onKeyReleased = (button: string, event: MouseEvent | undefined) => {
    if (event?.type === 'pointerdown') return;

    if (button === '{Done}') {
      setTimeout(() => {
        onDoneKeyPressed();
      }, 100);
    }

    if (button === '{bksp}') {
      onBackspaceKeyPressed?.();
    }

    if (button === '{shift}') {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    }

    if (button === '{123}' || button === '{abc}') {
      setLayoutName(layoutName === 'default' ? 'numeric' : 'default');
    }

    if (button === '{space}') {
      setLayoutName(`${capitalizeOnSpace ? 'shift' : 'default'}`);
      onCharacterPressed?.(' ');
    }

    if (button.length === 1) {
      setLayoutName('default');
      onCharacterPressed?.(button);
    }

    event?.preventDefault();
  };

  const handleOnChange = (event: string) => {
    onChange?.(event);
  };

  useEffect(() => {
    if (!numericKeyboard) setLayoutName('shift');
  }, [numericKeyboard]);

  return (
    <Keyboard
      syncInstanceInputs={true}
      inputName={inputName}
      keyboardRef={(r) => (keyboardRef.current = r)}
      layoutName={layoutName}
      disableCaretPositioning
      physicalKeyboardHighlight
      display={{
        '{bksp}': '⌫',
        '{shift}': '⇧',
        '{Done}': `${
          !error && numericKeyboard && value.length > 1 && !isCheckout
            ? t('button.next')
            : t('common.done')
        }`,
        '{space}': 'Space',
        '{Ferdig}': 'Done',
        '{123}': '123',
        '{abc}': 'ABC',
      }}
      onChange={(event) => handleOnChange(event)}
      layout={
        numericKeyboard
          ? keyboardLayoutOption[KeyboardLayout.NUMERIC]
          : keyboardLayoutOption[layout]
      }
      theme={
        numericKeyboard
          ? 'hg-theme-default hg-layout-numeric numeric-theme'
          : 'hg-theme-default hg-theme-custom'
      }
      buttonTheme={[
        {
          class: 'hg-custom-buttons',
          buttons: '{Done} {shift} {bksp} {123} {abc}',
        },
        {
          class:
            disableDoneButton === false
              ? 'hg-custom-buttons'
              : 'disabled-button',
          buttons: '{Done}',
        },
      ]}
      onKeyReleased={onKeyReleased}
      onRender={() => null}
    />
  );
};

export default KeyboardWrapper;
