import React, { ReactNode } from 'react';

// Components
import Footer from '../components/Footer/Footer';
import { Header } from 'common/components/Header';

interface Props {
  isValid: boolean;
  submitButtonText: string;
  children: ReactNode;
  hasPreviousButton?: boolean;
  hideNextButton?: boolean;
  title: string;
  previous?: () => void;
  loading?: boolean;
  decrementStep?: () => void;
  childrenClassName?: string;
}

export const ContentLayout = ({
  isValid,
  submitButtonText,
  loading = false,
  children,
  hideNextButton = false,
  hasPreviousButton = false,
  title,
  previous,
  decrementStep,
  childrenClassName = '',
}: Props) => {
  return (
    <div className="flex flex-col w-full h-full gap-4 text-left pr-28 pl-28">
      {/* TITLE */}
      <Header
        title={title}
        className="text-center flex mb-16 text-tertiary-stronger text-[3.0rem] leading-relaxed 2xl:text-[5rem] justify-center"
        textSize=""
      />
      <div className="relative overflow-auto xl:mt-6 2xl:mt-10 grow justify-center align-middle items-center">
        {/* CONTENT */}
        <div
          className={`relative flex flex-col w-full justify-center ${childrenClassName}`}
        >
          {children}
        </div>
      </div>

      {/* FOOTER */}
      <div className="h-[7rem] 2xl:h-[10rem] md:mb-10">
        <Footer
          isValid={isValid}
          loading={loading}
          nextTitle={submitButtonText}
          hideNextButton={hideNextButton}
          previous={previous}
          decrementStep={decrementStep}
          hasPreviousButton={hasPreviousButton}
        />
      </div>
    </div>
  );
};

export default ContentLayout;
