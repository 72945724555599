import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

// Context
import { LayoutContext } from 'context/LayoutContext';

// Config
import configuration from 'config';

const ThankYou = () => {
  // State
  const [counter, setCounter] = useState(
    configuration().secondsToRedirect || 10
  );

  // Hooks
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    settings: { theme },
  } = useContext(LayoutContext);

  // Declaration
  const isCheckin = params.action === 'checkin';

  // Effects
  useEffect(() => {
    const timer = setTimeout(() => {
      if (counter === 1) {
        navigate('/');
        return;
      }
      setCounter(counter - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [counter]);

  return (
    <div className="flex flex-col justify-center gap-10 mt-4 place-items-center 2xl:mt-24">
      <p
        className="text-tertiary-stronger text-[3rem] font-semibold break-words text-center max-w-[45rem]"
        style={{ color: theme.colors.tertiaryStrong }}
      >
        {isCheckin ? t('pages.thankyou.check') : t('pages.thankyou.goodbye')}
      </p>

      <div className="text-black max-w-[30rem] break-words text-center text-xl">
        <p className="" style={{ color: theme.colors.tertiaryStrong }}>
          {isCheckin && t('pages.thankyou.hostHasBeenNotified')}
        </p>
        <p
          className="text-primary-light text-[1.5rem] font-normal pt-10"
          style={{ color: theme.colors.primaryLight }}
        >
          {`${t('pages.thankyou.redirect')} ${counter} ${t(
            'common.secondsWithThreeDots'
          )}`}
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
