import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

// Services
import checkinServices from 'services/checkin.service';

// Context
import { InitialSettingsData } from './LayoutContext';

export const useLayoutHook = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) => {
  // Hooks
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { language } = i18n;

  // State
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(InitialSettingsData);
  const [fetchedSettings, setFetchedSettings] = useState(false);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [countryCodeAndFlag, setCountryCodeAndFlag] = useState({
    countryCode: '',
    countryFlag: '',
  });

  // handlers
  const fetchSettings = async (resetDefaults = false) => {
    if (!isAuthenticated) {
      return;
    }

    setLoadingSettings(true);
    try {
      const settings = await checkinServices.fetchSettings();
      if (resetDefaults) {
        i18n.changeLanguage(settings.language.toLowerCase());
      }
      setSettings(settings);

      if (settings.location.companies?.length === 0) {
        navigate('/no-company-available');
      }

      if (settings.isCheckInOnly === 1) {
        navigate('/checkin');
      }
    } catch (error) {
      navigate('/no-settings-available');
      // eslint-disable-next-line no-console
      console.log('ERR', error);
    }
    setLoadingSettings(false);
    setFetchedSettings(true);
  };

  const initLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const finishLoading = useCallback(() => {
    setLoading(false);
  }, []);

  // Effects
  useEffect(() => {
    fetchSettings();
  }, [isAuthenticated]);

  useEffect(() => {
    const handleLanguageChange = () => {
      if (language === 'en') {
        return setCountryCodeAndFlag({ countryCode: '44', countryFlag: 'gb' });
      }

      if (language === 'no') {
        return setCountryCodeAndFlag({ countryCode: '47', countryFlag: 'no' });
      }

      if (language === 'sw') {
        return setCountryCodeAndFlag({ countryCode: '46', countryFlag: 'se' });
      }
      if (language === 'da') {
        return setCountryCodeAndFlag({ countryCode: '45', countryFlag: 'dk' });
      }
    };

    handleLanguageChange();
  }, [language]);

  return {
    settings,
    loading,
    initLoading,
    finishLoading,
    fetchSettings,
    loadingSettings,
    countryCodeAndFlag,
    window,
    fetchedSettings,
  };
};
