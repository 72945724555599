import { useState } from 'react';
import { useNavigate } from 'react-router';

// Context
import { InitialCheckinData, CheckinContextProps } from './CheckinContext';

// Types
import {
  CheckinApiResponseDataType,
  CheckinDataType,
  InvitesApiDataType,
  PersonalInformationDataType,
  PhoneNumberDataType,
  QuestionType,
  RequirementDataType,
  VisitInformationDataType,
} from 'common/types/CheckinData.type';
import checkinServices from 'services/checkin.service';
import { ApiError } from 'utils/api';
import { toast } from 'react-toastify';
import { useLayoutHook } from './useLayoutHook';

// Printer labels
import { GjestebokLabel } from 'common/labels/GjestebokLabel';
import { GjestebokLabelQR } from 'common/labels/GjestebokLabelQR';
import { GjestebokQRWithLogo } from 'common/labels/GjestebokQRWithLogo';
import { GjestebokWithLogo } from 'common/labels/GjestebokWithLogo';

// Utils
// eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/ban-ts-comment
// @ts-ignore
const dymo = window['dymo'];

export const useCheckinData = (): CheckinContextProps => {
  const navigate = useNavigate();
  const [data, setData] = useState<CheckinDataType>(InitialCheckinData);
  const [step, setStep] = useState(1);
  const [isSaving, _setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const layout = useLayoutHook({ isAuthenticated: true });
  const [responseCheckInData, setResponseCheckInData] =
    useState<CheckinApiResponseDataType>();

  // Handlers
  const setIsSaving = (state: boolean) => _setIsSaving(state);
  const incrementStep = () => setStep(step + 1);
  const decrementStep = () => {
    step > 1 && setStep(step - 1);
    if (step === 1) navigate('/home');
  };

  const setHseQuestions = (question: QuestionType[]) =>
    setData({ ...data, hseQuestions: question });
  const setRequirements = (requirements: RequirementDataType[]) =>
    setData({ ...data, requirements });
  const setPhoneNumber = (
    phoneNumber: PhoneNumberDataType,
    personalInformation?: PersonalInformationDataType | undefined
  ) =>
    setData({
      ...data,
      phoneNumber,
      ...(personalInformation && { personalInformation }),
    });
  const setCompanyId = (companyId: number) => setData({ ...data, companyId });
  const setPersonalInformation = (
    personalInformation: PersonalInformationDataType
  ) => setData({ ...data, personalInformation });
  const setVisitInformation = (visitInformation: VisitInformationDataType) =>
    setData({ ...data, visitInformation });
  const setVisitInformationResponse = (
    checkinApiResponseDataType: CheckinApiResponseDataType
  ) => setResponseCheckInData(checkinApiResponseDataType);

  const submitCheckinData = async (data: CheckinDataType): Promise<void> => {
    setLoading(true);
    try {
      const res = await checkinServices.checkin(data);
      const printers = dymo.label.framework.getPrinters();
      setVisitInformationResponse(res.data);
      if (layout.settings.isPrintBadge && printers.length > 0) {
        const printer = printers[0];
        let selectedLabel = '';

        if (layout.settings.print_company_logo_on_badge) {
          selectedLabel = layout.settings.isQrCode
            ? GjestebokQRWithLogo
            : GjestebokWithLogo;
        } else {
          selectedLabel = layout.settings.isQrCode
            ? GjestebokLabelQR
            : GjestebokLabel;
        }

        const label = dymo.label.framework.openLabelXml(selectedLabel);

        if (
          layout.settings.print_company_logo_on_badge &&
          res.data.company_logo_base64
        ) {
          label.setObjectText('GRAPHIC', res.data.company_logo_base64);
        }

        label.setObjectText(
          'lblMeetingOwnerName',
          `${res.data.first_name} ${res.data.last_name}`
        );

        label.setObjectText(
          'lblCompanyName',
          `${res.data.company ? res.data.company : ''}`
        );

        label.setObjectText(
          'lblEMPName',
          `${res.data.employee.first_name} ${res.data.employee.last_name}`
        );
        label.setObjectText('lblDateTime', `${res.data.check_in}`);
        label.setObjectText('lblBarcode', `${res.data.barcode}`);

        label.print(printer.name);
      } else {
        if (layout.settings.isPrintBadge) toast.error('No printer available');
      }
      navigate('/thank-you/checkin');
    } catch (error) {
      const err = error as ApiError;
      toast.error(err.message);
    }
    setLoading(false);
  };

  const inviteOnlyChecking = async (
    phoneNumberData: PhoneNumberDataType,
    invite: InvitesApiDataType
  ) => {
    const allData = {
      phoneNumber: phoneNumberData,
      personalInformation: {
        firstName: invite.first_name,
        lastName: invite.last_name,
        companyName: invite.company,
        rememberMe: invite.remember_me || false,
      },
      visitInformation: {
        host: invite?.employee.first_name,
        employee: `${invite.employee.id}`,
        department: `${invite.employee.id}`,
        visitPurpose: invite?.visit_pupose || null,
      },
      companyId: invite?.employee_company.id,
      requirements: data.requirements,
      hseQuestions: data.hseQuestions,
    };

    await submitCheckinData(allData);
  };

  return {
    setVisitInformationResponse,
    data,
    isSaving,
    step,
    setCompanyId,
    setStep,
    setIsSaving,
    incrementStep,
    decrementStep,
    setRequirements,
    setHseQuestions,
    setPhoneNumber,
    setPersonalInformation,
    setVisitInformation,
    submitCheckinData,
    inviteOnlyChecking,
    loading,
    responseCheckInData,
  };
};
