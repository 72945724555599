// Assets
import { ReactComponent as KeyboardSvg } from 'assets/svg/keyboard.svg';

interface ToggleProps {
  onClick: () => void;
  isToggled: boolean;
}

const Toggle = ({ onClick, isToggled }: ToggleProps) => {
  const toggleClass = 'transform translate-x-7';
  const bgColor = isToggled ? 'bg-primary' : 'bg-primary-light';

  return (
    <div className="flex items-center gap-2 relative h-10">
      <KeyboardSvg className="w-8" />
      <div
        className={`md:w-14 md:h-7 w-12 h-6 flex items-center ${bgColor} rounded-full p-0.5 cursor-pointer transition-colors duration-300`}
        onClick={onClick}
      >
        <div
          className={
            'bg-white md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md transform transition-transform duration-300' +
            (isToggled ? '' : ` ${toggleClass}`)
          }
        ></div>
      </div>
    </div>
  );
};

export default Toggle;
