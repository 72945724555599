import React, { ReactNode, useContext, useRef, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldError } from 'react-hook-form';

// Context
import { LayoutContext } from 'context/LayoutContext';

// Components
import KeyboardWrapper, {
  KeyboardLayout,
} from 'common/components/Input/KeyboardWrapper';
import Modal, { ModalHandle } from 'common/components/Modal/Modal';

import Input from '../../common/components/Input/Input';
import { AutoSuggestion } from '.';

export interface SelectOptions {
  label: string | ReactNode | number;
  value: string;
}

interface KeyboardInputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  name: string;
  isNumericKeyboard?: boolean;
  keyboardLayout?: KeyboardLayout;
  placeholder: string;
  isCheckout?: boolean;
  getSearchTerm?: (data: string) => void;
  size?: 'sm' | 'lg';
  label: string;
  options?: SelectOptions[];
  className?: string;
  capitalizeOnSpace?: boolean;
  wrapperClassName?: string;
  isSearchableSelect?: boolean;
  hideErrorOnEmptyValue?: boolean;
  flag?: string;
  submitData?: () => void;
  required?: boolean;
  countryCode?: string;
  prepend?: string;
  loading?: boolean;
  append?: ReactNode;
  userPrefersPhysicalKeyboard?: boolean;
  disableDoneButton: boolean;
}

export const KeyboardInput = React.forwardRef<
  HTMLInputElement,
  KeyboardInputProps
>(
  (
    {
      control,
      label,
      keyboardLayout = KeyboardLayout.QWERTY,
      placeholder,
      loading = false,
      isSearchableSelect,
      disableDoneButton,
      isNumericKeyboard = false,
      capitalizeOnSpace = false,
      getSearchTerm,
      append = null,
      flag = '',
      hideErrorOnEmptyValue = false,
      className = '',
      userPrefersPhysicalKeyboard = false,
      isCheckout = false,
      wrapperClassName = '',
      options,
      submitData,
      required,
      size = 'lg',
      name,
      prepend,
    },
    ref
  ) => {
    // Hooks
    const keyboardRef = useRef(null);
    const modalRef = useRef<ModalHandle>(null);
    const language = i18next.language;
    const { t } = useTranslation();
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const {
      settings: { showTouchKeyboard },
    } = useContext(LayoutContext);

    const defaultValue = control._defaultValues[name];

    // Handlers
    const onDoneButtonPressed = () => {
      submitData?.();
      modalRef.current?.hide();
    };

    const onBackspacePressed = (
      onChange: (x: string) => void,
      _value: string
    ) => {
      const trimedValue = _value.slice(0, -1);
      onChange(trimedValue);
      getSearchTerm?.(trimedValue);
    };

    const onCharacterPressed = (
      onChange: (x: string) => void,
      _value: string,
      newCharacter: string
    ) => {
      onChange(`${_value}${newCharacter}`);
      getSearchTerm?.(`${_value}${newCharacter}`);
    };

    const isTouchDevice = () => {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.maxTouchPoints > 0
      );
    };

    const getError = (error: FieldError | undefined, value: string) => {
      const shouldTranslate = error?.message?.split('translate_:') || [];

      if (shouldTranslate.length > 1) {
        return { ...error, message: t(shouldTranslate[1]) };
      }

      if (hideErrorOnEmptyValue || value?.length >= 1) {
        return '';
      }

      return error;
    };

    const onOptionClicked = (
      onChange: (x: string) => void,
      value: string,
      employeeName: string
    ) => {
      onChange(value);
      const employe = options?.find((item) => item.value === value);
      setSelectedEmployee(employe?.label as string);
      getSearchTerm?.(employeeName);
      modalRef.current?.hide();
    };

    const handleOnModalShow = (onChange: (x: string) => void) => {
      modalRef.current?.show();
      setSelectedEmployee('');
      isSearchableSelect && onChange('');
      getSearchTerm?.('');
    };

    // JSX
    return (
      <Controller
        control={control}
        rules={{ required: true }}
        name={name}
        defaultValue={defaultValue}
        render={({
          field: { onChange, value },
          fieldState: { error, isDirty },
        }) => (
          <>
            <Input
              type="text"
              placeholder={placeholder}
              onChange={onChange}
              className={className}
              defaultValue={defaultValue}
              wrapperClassName={wrapperClassName}
              label={label}
              onClick={
                (showTouchKeyboard === 1 && isTouchDevice()) ||
                !userPrefersPhysicalKeyboard
                  ? () => handleOnModalShow(onChange)
                  : undefined
              }
              append={append}
              readOnly={showTouchKeyboard === 1 && isTouchDevice()}
              name={name}
              flag={flag}
              ref={ref}
              errors={{ [name]: getError(error, value) }}
              value={!selectedEmployee ? value : selectedEmployee}
              required={required}
              prepend={prepend}
            />
            {((!userPrefersPhysicalKeyboard &&
              showTouchKeyboard === undefined &&
              isTouchDevice()) ||
              showTouchKeyboard === 1) && (
              <Modal ref={modalRef} size={size}>
                <div className="flex flex-col gap-y-20">
                  <Input
                    wrapperClassName={`!max-w-full  ${
                      isNumericKeyboard ? 'px-3' : 'px-10 mt-5'
                    }`}
                    label={label}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    onChange={onChange}
                    value={!selectedEmployee ? value : selectedEmployee}
                    defaultValue={defaultValue ?? ''}
                    flag={flag}
                    name={name}
                    errors={{ [name]: getError(error, value) }}
                    type="text"
                    readOnly={isTouchDevice()}
                    placeholder={placeholder}
                    required={required}
                    prepend={prepend}
                  />

                  {!isNumericKeyboard && (
                    <AutoSuggestion
                      loading={loading}
                      options={options || []}
                      onSelect={(value, employeeName) =>
                        onOptionClicked(onChange, value, employeeName)
                      }
                    />
                  )}
                  <KeyboardWrapper
                    inputName={name}
                    error={error}
                    value={value}
                    capitalizeOnSpace={capitalizeOnSpace}
                    isCheckout={isCheckout}
                    isDirty={isDirty}
                    layout={
                      language === 'no'
                        ? KeyboardLayout.NORWEGIANKEYBOARD
                        : keyboardLayout
                    }
                    keyboardRef={keyboardRef}
                    onChange={() => onChange}
                    onCharacterPressed={(newCharacter: string) => {
                      onCharacterPressed(onChange, value, newCharacter);
                    }}
                    onBackspaceKeyPressed={() =>
                      onBackspacePressed(onChange, value)
                    }
                    numericKeyboard={isNumericKeyboard}
                    onDoneKeyPressed={
                      disableDoneButton ? () => null : onDoneButtonPressed
                    }
                    disableDoneButton={disableDoneButton}
                  />
                </div>
              </Modal>
            )}
          </>
        )}
      />
    );
  }
);

export default KeyboardInput;
