import { useContext } from 'react';
import { LayoutContext } from 'context/LayoutContext';

const Copyright = () => {
  // Hook
  const {
    settings: { theme },
  } = useContext(LayoutContext);
  // Declaration
  const dataFromLocalStorage = localStorage.getItem('auth') || '{}';
  const { deviceCode } = JSON.parse(dataFromLocalStorage);

  return (
    <h1
      className="absolute bottom-0 right-0 p-0 px-5 m-0 my-2 text-gray-900"
      style={{ color: theme.colors.tertiaryStrong, opacity: 0.5 }}
    >
      Copyright &copy; Digitaliseringsfabrikken AS
      {deviceCode ? ` - ${deviceCode}` : ''}
    </h1>
  );
};

export default Copyright;
