import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';

// Context
import { LayoutContext } from 'context/LayoutContext';

// Services
import checkinServices from 'services/checkin.service';

// Utils
import { ApiError } from 'utils/api';

// Components
import { Button } from 'common/components/Button';
import { AbsoluteSpinner } from 'common/components/Spinners';
import { Header } from 'common/components/Header';
import { GlassBoxWrapper } from 'common/components/GlassBoxWrapper';

const Home = () => {
  // State
  const [countdown, setCountdown] = useState(0);

  // Hooks
  const { t } = useTranslation();
  const {
    loading,
    fetchSettings,
    initLoading,
    settings: { clientName, location },
    finishLoading,
  } = useContext(LayoutContext);

  // Declaration
  const hasPackageButton = location?.packageDelivery?.isActive;

  const handleSettingsFetch = async () => {
    initLoading();
    await fetchSettings(true);
    finishLoading();
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [countdown]);

  useEffect(() => {
    handleSettingsFetch();
  }, []);

  const notifyPackageDelivery = async () => {
    setCountdown(10);
    try {
      const notify = await checkinServices.notifyPackageDelivery();
      toast.success(notify.message);
    } catch (error) {
      const err = error as ApiError;
      toast.error(`${err.message} !`);
    }
  };

  return (
    <div className="flex flex-col h-full items-center justify-center align-middle ">
      <AbsoluteSpinner show={loading} />

      {!loading && (
        <GlassBoxWrapper>
          {/* TITLE  */}
          <div className="flex flex-col mb-12 place-items-center">
            <Header title={t('common.welcome')} textSize="2xl" />
            <Header
              title={clientName || t('pages.home.defaultCompanyName')}
              textSize="3xl"
            />
          </div>
          {/* BUTTONS CHECKIN CHECKOUT */}
          <div className="flex flex-col justify-center md:flex-row place-items-center">
            <div className="flex flex-col-reverse md:flex-row  gap-[4rem] md:gap-[15rem]">
              <Link to="/checkout">
                <Button
                  className="min-w-[18rem] text-2xl lg:text-[1.9rem]"
                  size="lg"
                  round="lg"
                  full
                  title={t('button.checkOut')}
                />
              </Link>
              <Link to="/checkin">
                <Button
                  className="min-w-[18rem] text-2xl lg:text-[1.9rem]"
                  size="lg"
                  round="lg"
                  title={t('button.checkIn')}
                />
              </Link>
            </div>
          </div>
          {/* PACKAGE DELIVERY BUTTON */}
          {hasPackageButton ? (
            <div className="flex flex-col items-center gap-3 mt-8 ">
              <Header
                className="text-[26px]"
                title={t('pages.home.sendNotifyPackageDelivery')}
              />

              <Button
                disabled={!!countdown}
                className="min-w-[18rem] text-2xl lg:text-[1.9rem] opacity-90"
                title={
                  countdown ? countdown.toString() : t('pages.home.notify')
                }
                onClick={notifyPackageDelivery}
                size="lg"
                round="lg"
                full
              />
            </div>
          ) : null}
        </GlassBoxWrapper>
      )}
    </div>
  );
};

export default Home;
