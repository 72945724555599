import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  ReactNode,
  useImperativeHandle,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

// Components
import { AbsoluteSpinner } from 'common/components/Spinners/AbsoluteSpinner';
import { ReactComponent as CloseIconSvg } from 'assets/svg/close-icon.svg';

export type ModalHandle = {
  show: () => void;
  hide: () => void;
};

export type ModalProps = {
  contentClassName?: string;
  title?: string;
  className?: string;
  size?: 'sm' | 'lg';
  loading?: boolean;
  onClose?: () => void;
  children: ReactElement | ReactNode;
  centeredLayout?: boolean;
  showModal?: boolean;
  disableClose?: boolean;
};

const Modal: ForwardRefRenderFunction<ModalHandle, ModalProps> = (
  {
    children,
    title,
    contentClassName = '',
    loading = false,
    onClose,
    size = 'sm',
    className = '',
    centeredLayout = false,
    showModal = false,
    disableClose = false,
  }: ModalProps,
  ref
): ReactElement | null => {
  // Sizes
  const SIZE_CLASSES = {
    sm: 'md:max-w-md',
    lg: 'md:max-w-[65rem] 2xl:max-w-[95rem]',
  };

  // State
  const [show, setShow] = useState(showModal);

  // Handlers
  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
    onClose?.();
  };

  // Ref interface
  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const renderModal = () => {
    return (
      <div className="fixed top-0 bottom-0 left-0 right-0 w-screen h-screen p-10 z-[100]">
        <div
          className={`
              fixed   flex flex-col right-1/2 bottom-1/2 top-1/2 left-1/2 -translate-x-1/2 z-50 mx-auto -translate-y-1/2 BESNIKKKKK
              transform rounded-theme bg-white p-4 rounded-lg
             ${SIZE_CLASSES[size]} w-full
              max-h-[80vh] h-fit min-h-[400px] 
              glass-white
              ${className}
            `}
        >
          <AbsoluteSpinner show={loading} />
          <div
            className={`flex  ${
              centeredLayout ? 'justify-center' : 'justify-between'
            }`}
          >
            <div>
              <h1 className="pb-4 text-lg font-bold">{title}</h1>
            </div>
            {!centeredLayout && (
              <div>
                <span
                  onClick={handleHide}
                  className="text-lg cursor-pointer z-60"
                >
                  <CloseIconSvg className="h-4" />
                </span>
              </div>
            )}
          </div>

          <div
            className={`overflow-y-auto customScroll max-h-full relative ${contentClassName}`}
          >
            {children}
          </div>
        </div>
        <div
          onClick={() => !disableClose && handleHide()}
          className="absolute top-0 bottom-0 left-0 right-0 w-full h-full glass-gray"
        />
      </div>
    );
  };

  // Renderers
  if (!show) return null;

  return createPortal(renderModal(), document.body);
};

export default forwardRef(Modal);
