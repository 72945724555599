import { ReactElement } from 'react';

interface Props {
  show?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const AbsoluteSpinner = ({
  show = false,
  size = 'md',
}: Props): ReactElement => {
  const spinnerSize =
    size === 'sm' ? 'w-10 h-10' : size === 'md' ? 'w-14 h-14' : 'w-16 h-16';

  if (!show) return <></>;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-200 bg-opacity-75">
      <div className="flex items-center justify-center">
        <div className="flex items-center justify-center space-x-2 animate-pulse">
          <div
            style={{ borderTopColor: 'transparent' }}
            className={`${spinnerSize} border-4 border-blue-400 h-full border-dashed rounded-full animate-spin`}
          ></div>
        </div>
      </div>
    </div>
  );
};
