import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Types
import { PersonalInformationDataType } from 'common/types/CheckinData.type';

// Context
import { CheckinContext } from 'context/CheckinContext';

// Layout
import ContentLayout from 'common/layouts/ContentLayout';
import { Checkbox } from 'common/components/Checkbox';

// Containers
import { KeyboardInput } from 'containers/KeyboardInput';

// Schema
const getSchema = () =>
  yup.object().shape({
    firstName: yup
      .string()
      .required('translate_:errorsFromYup.required.firstName')
      .max(20, 'translate_:errorsFromYup.maxCharacters.firstName'),
    lastName: yup
      .string()
      .min(2, 'translate_:errorsFromYup.minCharacters.lastName')
      .required('translate_:errorsFromYup.required.lastName')
      .max(20, 'translate_:errorsFromYup.maxCharacters.lastName'),
    companyName: yup
      .string()
      .max(20, 'translate_:errorsFromYup.maxCharacters.company'),
    rememberMe: yup.bool().default(false),
  });

interface Props {
  submitButtonText: string;
  hasPreviousButton: boolean;
}

export const PersonalInformation = React.forwardRef<HTMLInputElement, Props>(
  ({ hasPreviousButton, submitButtonText }, _ref) => {
    // Hooks
    const { t } = useTranslation();
    // Context
    const { setPersonalInformation, incrementStep, decrementStep, data } =
      useContext(CheckinContext);
    const { personalInformation } = data;

    // Form
    const {
      register,
      handleSubmit,
      control,
      getValues,
      trigger,
      formState: { isValid },
    } = useForm<PersonalInformationDataType>({
      mode: 'onChange',
      resolver: yupResolver(getSchema()),
      defaultValues: personalInformation,
    });

    const innerSubmit = (data: PersonalInformationDataType) => {
      setPersonalInformation(data);
      incrementStep();
    };

    const previous = () => {
      setPersonalInformation(getValues());
      decrementStep();
    };

    useEffect(() => {
      if (personalInformation.firstName && personalInformation.lastName) {
        trigger();
      }
    }, []);

    return (
      <form
        className="h-full fw-full"
        onSubmit={handleSubmit(innerSubmit)}
        autoComplete="off"
      >
        <ContentLayout
          isValid={isValid}
          submitButtonText={submitButtonText}
          hasPreviousButton={hasPreviousButton}
          previous={previous}
          title={t('personalInformation.enterPersonalInfo')}
          childrenClassName="max-w-2xl ml-auto mr-auto md:gap-3 2xl:gap-6"
        >
          <KeyboardInput
            {...register('firstName')}
            control={control}
            disableDoneButton={false}
            capitalizeOnSpace={true}
            size="lg"
            label={t('personalInformation.label.firstname') || 'First Name'}
            placeholder={t(
              'personalInformation.placeholder.enterYourFirstname'
            )}
            required
          />

          <KeyboardInput
            {...register('lastName')}
            disableDoneButton={false}
            control={control}
            capitalizeOnSpace={true}
            label={t('personalInformation.label.lastname') || 'Last Name'}
            placeholder={t('personalInformation.placeholder.enterYourLastname')}
            required
          />
          <KeyboardInput
            {...register('companyName')}
            disableDoneButton={false}
            control={control}
            label={t('personalInformation.label.company') || 'Company'}
            placeholder={t('personalInformation.placeholder.enterYourCompany')}
          />
          <Checkbox
            label={t('personalInformation.label.rememberMe', 'Remember me')}
            placeholder={t(
              'personalInformation.label.rememberMe',
              'Remember me'
            )}
            {...register('rememberMe')}
          />
        </ContentLayout>
      </form>
    );
  }
);

export default PersonalInformation;
