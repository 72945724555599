import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { CheckinContext } from 'context/CheckinContext';
import { LayoutContext } from 'context/LayoutContext';

// Hooks
import { useCheckinData } from 'context/useCheckinData';

// Containers
import {
  Requirements,
  PersonalInformation,
  PhoneNumber,
  VisitInformation,
  Company,
} from 'containers/Forms';

// Components
import { AbsoluteSpinner } from 'common/components/Spinners';
import CurrentStep from 'common/components/CurrentStep';
import HseQuestions from 'containers/Forms/HseQuestions';

const Checkin = () => {
  // Hooks
  const { t } = useTranslation();
  const checkinDataProps = useCheckinData();
  const { settings, loadingSettings, fetchSettings } =
    useContext(LayoutContext);
  const { step } = checkinDataProps;
  const hasRequirements = !!settings.visitorClaims.length;
  const hasHseQuestions = !!settings.hseQuestionnaire?.length;
  const hansMultipleCompanies =
    (settings?.location?.companies?.length || 1) > 1;

  useEffect(() => {
    fetchSettings();
  }, []);

  let steps = [
    ...(hansMultipleCompanies
      ? [
          {
            title: t('steps.company'),
            step: -1,
            content: (
              <Company
                submitButtonText={t('button.next')}
                hasPreviousButton={hasRequirements}
              />
            ),
          },
        ]
      : []),
    ...(hasRequirements
      ? [
          {
            title: t('requirments.title'),
            step: -1,
            content: <Requirements submitButtonText={t('button.agree')} />,
          },
        ]
      : []),
    ...(hasHseQuestions
      ? [
          {
            title: 'HSE Questions',
            step: -1,
            content: <HseQuestions />,
          },
        ]
      : []),
    {
      title: t('phoneNumber.title'),
      step: -1,
      content: (
        <PhoneNumber
          submitButtonText={t('button.next')}
          hasPreviousButton={hansMultipleCompanies}
        />
      ),
    },

    {
      title: t('personalInformation.title'),
      step: -1,
      content: (
        <PersonalInformation
          submitButtonText={t('button.next')}
          hasPreviousButton
        />
      ),
    },

    {
      title: t('visitInformation.title'),
      step: -1,
      content: (
        <VisitInformation
          submitButtonText={t('button.checkIn')}
          hasPreviousButton
        />
      ),
    },
  ];

  steps = steps.map((item, index) => {
    return { ...item, step: index + 1 };
  });

  return (
    <CheckinContext.Provider value={checkinDataProps}>
      {loadingSettings ? (
        <AbsoluteSpinner show={loadingSettings} size="lg" />
      ) : (
        <CurrentStep steps={steps} currentStep={step} />
      )}
    </CheckinContext.Provider>
  );
};

export default Checkin;
