import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';

// Context
import { CheckinContext } from 'context/CheckinContext';
import { LayoutContext } from 'context/LayoutContext';

// Types
import { RequirementDataType } from 'common/types/CheckinData.type';

// Components
import { Checkbox } from 'common/components/Checkbox';
import { getLanguagePrefix } from 'utils/helpers';
import ContentLayout from 'common/layouts/ContentLayout';

interface Props {
  submitButtonText: string;
}

interface RequirementType {
  requirements: RequirementDataType[];
}

export const Requirements = ({ submitButtonText }: Props) => {
  const { t, i18n } = useTranslation();
  const {
    settings: { visitorClaims },
  } = useContext(LayoutContext);

  const languageSelected = getLanguagePrefix(i18n.language, 'claimText');

  // Context
  const { setRequirements, incrementStep, data } = useContext(CheckinContext);

  const { requirements } = data;

  // Form
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RequirementType>({
    mode: 'onChange',
    defaultValues: {
      requirements: requirements.length
        ? requirements
        : visitorClaims.map(
            (all): RequirementDataType => ({
              ...all,
              checked: false,
            })
          ),
    },
  });

  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'requirements', // unique name for your Field Array
  });

  // Hanlders
  const handleOnSubmit = (data: RequirementType) => {
    setRequirements(data.requirements);
    incrementStep();
  };

  const getError = () => {
    if (Object.keys(errors).length) {
      return t('errorsFromYup.required.requirements');
    }
    return null;
  };

  // Renderers
  const renderFields = () =>
    fields.map((_field, index) => {
      const field = _field as RequirementDataType;
      return (
        <Checkbox
          label={field[languageSelected]}
          required={field.isBlocker}
          placeholder={field.claimText}
          key={field.claimText} // important to include key with field's id
          {...register(`requirements.${index}.checked`, {
            required: field.isBlocker,
          })}
        />
      );
    });

  return (
    <form
      className="w-full h-full"
      onSubmit={handleSubmit(handleOnSubmit)}
      autoComplete="off"
    >
      <ContentLayout
        submitButtonText={submitButtonText}
        isValid={isValid}
        title={t('requirments.enterRequirments').toString()}
        childrenClassName="gap-3 w-12/12 lg:8/12 xl:w-6/12 ml-auto mr-auto"
      >
        {renderFields()}
        <span className="text-2xl text-center text-red-500">{getError()}</span>
      </ContentLayout>
    </form>
  );
};
