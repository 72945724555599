/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */

import { createContext } from 'react';

// Types
import {
  CheckinDataType,
  PhoneNumberDataType,
  PersonalInformationDataType,
  VisitInformationDataType,
  RequirementDataType,
  CheckinApiResponseDataType,
  InvitesApiDataType,
  QuestionType,
} from 'common/types/CheckinData.type';

export const InitialCheckinData: CheckinDataType = {
  phoneNumber: {
    countryCode: '+',
    phoneNumber: '',
    phoneNumberWithCountryCode: '',
  },
  personalInformation: {
    companyName: '',
    firstName: '',
    lastName: '',
    rememberMe: false,
  },
  visitInformation: {
    department: '',
    employee: '',
    host: '',
    visitPurpose: '',
  },
  companyId: null,
  requirements: [],
  hseQuestions: [],
};

export interface CheckinContextProps {
  setRequirements: (_data: RequirementDataType[]) => void | null;
  setHseQuestions: (_data: QuestionType[]) => void | null;
  setPhoneNumber: (
    _data: PhoneNumberDataType,
    _personalInformation?: PersonalInformationDataType | undefined
  ) => void | null;
  setCompanyId: (_data: number) => void | null;
  setPersonalInformation: (_data: PersonalInformationDataType) => void | null;
  setVisitInformation: (_data: VisitInformationDataType) => void | null;
  setVisitInformationResponse: (
    _data: CheckinApiResponseDataType
  ) => void | null;
  setStep: (_step: number) => void | null;
  setIsSaving: (_isSaving: boolean) => void | null;
  incrementStep: () => void | null;
  decrementStep: () => void | null;
  submitCheckinData: (_data: CheckinDataType) => Promise<void | null>;
  inviteOnlyChecking: (
    _phoneNumberData: PhoneNumberDataType,
    _invite: InvitesApiDataType
  ) => Promise<void | null>;
  isSaving: boolean;
  data: CheckinDataType;
  step: number;
  loading: boolean;
  responseCheckInData: CheckinApiResponseDataType | undefined;
}

export const CheckinContext = createContext<CheckinContextProps>({
  setVisitInformationResponse(_data: CheckinApiResponseDataType): void | null {
    return undefined;
  },
  setHseQuestions: (_data: QuestionType[]) => null,
  setRequirements: (_data: RequirementDataType[]) => null,
  setPhoneNumber: (
    _data: PhoneNumberDataType,
    _personalInformation?: PersonalInformationDataType | undefined
  ) => null,
  setCompanyId: (_data: number) => null,
  setPersonalInformation: (_data: PersonalInformationDataType) => null,
  setVisitInformation: (_data: VisitInformationDataType) => null,
  setStep: (_step: number) => null,
  setIsSaving: (_isSaving: boolean) => null,
  incrementStep: () => null,
  decrementStep: () => null,
  submitCheckinData: async (_data: CheckinDataType) => new Promise(() => null),
  inviteOnlyChecking: async (
    _phoneNumberData: PhoneNumberDataType,
    _invite: InvitesApiDataType
  ) => new Promise(() => null),
  isSaving: false,
  data: InitialCheckinData,
  step: 1,
  loading: false,
  responseCheckInData: undefined,
});
