export const GjestebokLabelQR =
  '<?xml version="1.0" encoding="utf-8"?>\n' +
  '<DieCutLabel Version="8.0" Units="twips">\n' +
  '\t<PaperOrientation>Landscape</PaperOrientation>\n' +
  '\t<Id>Shipping</Id>\n' +
  '\t<IsOutlined>false</IsOutlined>\n' +
  '\t<PaperName>30323 Shipping</PaperName>\n' +
  '\t<DrawCommands>\n' +
  '\t\t<RoundRectangle X="0" Y="0" Width="3060" Height="5715" Rx="270" Ry="270" />\n' +
  '\t</DrawCommands>\n' +
  '\t<ObjectInfo>\n' +
  '\t\t<AddressObject>\n' +
  '\t\t\t<Name>lblTitle</Name>\n' +
  '\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\n' +
  '\t\t\t<BackColor Alpha="0" Red="255" Green="255" Blue="255" />\n' +
  '\t\t\t<LinkedObjectName />\n' +
  '\t\t\t<Rotation>Rotation0</Rotation>\n' +
  '\t\t\t<IsMirrored>False</IsMirrored>\n' +
  '\t\t\t<IsVariable>True</IsVariable>\n' +
  '\t\t\t<GroupID>-1</GroupID>\n' +
  '\t\t\t<IsOutlined>False</IsOutlined>\n' +
  '\t\t\t<HorizontalAlignment>Left</HorizontalAlignment>\n' +
  '\t\t\t<VerticalAlignment>Top</VerticalAlignment>\n' +
  '\t\t\t<TextFitMode>ShrinkToFit</TextFitMode>\n' +
  '\t\t\t<UseFullFontHeight>True</UseFullFontHeight>\n' +
  '\t\t\t<Verticalized>False</Verticalized>\n' +
  '\t\t\t<StyledText>\n' +
  '\t\t\t\t<Element>\n' +
  '\t\t\t\t\t<String xml:space="preserve">Besøkende / Visitor</String>\n' +
  '\t\t\t\t\t<Attributes>\n' +
  '\t\t\t\t\t\t<Font Family="Arial" Size="14" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\n' +
  '\t\t\t\t\t</Attributes>\n' +
  '\t\t\t\t</Element>\n' +
  '\t\t\t</StyledText>\n' +
  '\t\t\t<ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>\n' +
  '\t\t\t<BarcodePosition>AboveAddress</BarcodePosition>\n' +
  '\t\t\t<LineFonts>\n' +
  '\t\t\t\t<Font Family="Arial" Size="14" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t</LineFonts>\n' +
  '\t\t</AddressObject>\n' +
  '\t\t<Bounds X="619.999999999999" Y="750" Width="2805" Height="390" />\n' +
  '\t</ObjectInfo>\n' +
  '\t<ObjectInfo>\n' +
  '\t\t<AddressObject>\n' +
  '\t\t\t<Name>lblMeetingOwnerName</Name>\n' +
  '\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\n' +
  '\t\t\t<BackColor Alpha="0" Red="255" Green="255" Blue="255" />\n' +
  '\t\t\t<LinkedObjectName />\n' +
  '\t\t\t<Rotation>Rotation0</Rotation>\n' +
  '\t\t\t<IsMirrored>False</IsMirrored>\n' +
  '\t\t\t<IsVariable>True</IsVariable>\n' +
  '\t\t\t<GroupID>-1</GroupID>\n' +
  '\t\t\t<IsOutlined>False</IsOutlined>\n' +
  '\t\t\t<HorizontalAlignment>Left</HorizontalAlignment>\n' +
  '\t\t\t<VerticalAlignment>Top</VerticalAlignment>\n' +
  '\t\t\t<TextFitMode>ShrinkToFit</TextFitMode>\n' +
  '\t\t\t<UseFullFontHeight>True</UseFullFontHeight>\n' +
  '\t\t\t<Verticalized>False</Verticalized>\n' +
  '\t\t\t<StyledText>\n' +
  '\t\t\t\t<Element>\n' +
  '\t\t\t\t\t<String xml:space="preserve">Meeting Owner</String>\n' +
  '\t\t\t\t\t<Attributes>\n' +
  '\t\t\t\t\t\t<Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\n' +
  '\t\t\t\t\t</Attributes>\n' +
  '\t\t\t\t</Element>\n' +
  '\t\t\t</StyledText>\n' +
  '\t\t\t<ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>\n' +
  '\t\t\t<BarcodePosition>AboveAddress</BarcodePosition>\n' +
  '\t\t\t<LineFonts>\n' +
  '\t\t\t\t<Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t</LineFonts>\n' +
  '\t\t</AddressObject>\n' +
  '\t\t<Bounds X="307" Y="1185" Width="2880" Height="330" />\n' +
  '\t</ObjectInfo>\n' +
  '\t<ObjectInfo>\n' +
  '\t\t<AddressObject>\n' +
  '\t\t\t<Name>lblCompanyName</Name>\n' +
  '\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\n' +
  '\t\t\t<BackColor Alpha="0" Red="255" Green="255" Blue="255" />\n' +
  '\t\t\t<LinkedObjectName />\n' +
  '\t\t\t<Rotation>Rotation0</Rotation>\n' +
  '\t\t\t<IsMirrored>False</IsMirrored>\n' +
  '\t\t\t<IsVariable>True</IsVariable>\n' +
  '\t\t\t<GroupID>-1</GroupID>\n' +
  '\t\t\t<IsOutlined>False</IsOutlined>\n' +
  '\t\t\t<HorizontalAlignment>Left</HorizontalAlignment>\n' +
  '\t\t\t<VerticalAlignment>Top</VerticalAlignment>\n' +
  '\t\t\t<TextFitMode>ShrinkToFit</TextFitMode>\n' +
  '\t\t\t<UseFullFontHeight>True</UseFullFontHeight>\n' +
  '\t\t\t<Verticalized>False</Verticalized>\n' +
  '\t\t\t<StyledText>\n' +
  '\t\t\t\t<Element>\n' +
  '\t\t\t\t\t<String xml:space="preserve">Company Name</String>\n' +
  '\t\t\t\t\t<Attributes>\n' +
  '\t\t\t\t\t\t<Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\n' +
  '\t\t\t\t\t</Attributes>\n' +
  '\t\t\t\t</Element>\n' +
  '\t\t\t</StyledText>\n' +
  '\t\t\t<ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>\n' +
  '\t\t\t<BarcodePosition>AboveAddress</BarcodePosition>\n' +
  '\t\t\t<LineFonts>\n' +
  '\t\t\t\t<Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t</LineFonts>\n' +
  '\t\t</AddressObject>\n' +
  '\t\t<Bounds X="307" Y="1515" Width="2880" Height="285" />\n' +
  '\t</ObjectInfo>\n' +
  '\t<ObjectInfo>\n' +
  '\t\t<AddressObject>\n' +
  '\t\t\t<Name>lblEMPName</Name>\n' +
  '\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\n' +
  '\t\t\t<BackColor Alpha="0" Red="255" Green="255" Blue="255" />\n' +
  '\t\t\t<LinkedObjectName />\n' +
  '\t\t\t<Rotation>Rotation0</Rotation>\n' +
  '\t\t\t<IsMirrored>False</IsMirrored>\n' +
  '\t\t\t<IsVariable>True</IsVariable>\n' +
  '\t\t\t<GroupID>-1</GroupID>\n' +
  '\t\t\t<IsOutlined>False</IsOutlined>\n' +
  '\t\t\t<HorizontalAlignment>Left</HorizontalAlignment>\n' +
  '\t\t\t<VerticalAlignment>Top</VerticalAlignment>\n' +
  '\t\t\t<TextFitMode>ShrinkToFit</TextFitMode>\n' +
  '\t\t\t<UseFullFontHeight>True</UseFullFontHeight>\n' +
  '\t\t\t<Verticalized>False</Verticalized>\n' +
  '\t\t\t<StyledText>\n' +
  '\t\t\t\t<Element>\n' +
  '\t\t\t\t\t<String xml:space="preserve">EMP Name</String>\n' +
  '\t\t\t\t\t<Attributes>\n' +
  '\t\t\t\t\t\t<Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\n' +
  '\t\t\t\t\t</Attributes>\n' +
  '\t\t\t\t</Element>\n' +
  '\t\t\t</StyledText>\n' +
  '\t\t\t<ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>\n' +
  '\t\t\t<BarcodePosition>AboveAddress</BarcodePosition>\n' +
  '\t\t\t<LineFonts>\n' +
  '\t\t\t\t<Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t</LineFonts>\n' +
  '\t\t</AddressObject>\n' +
  '\t\t<Bounds X="337" Y="1860" Width="2880" Height="315" />\n' +
  '\t</ObjectInfo>\n' +
  '\t<ObjectInfo>\n' +
  '\t\t<AddressObject>\n' +
  '\t\t\t<Name>lblDateTime</Name>\n' +
  '\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\n' +
  '\t\t\t<BackColor Alpha="0" Red="255" Green="255" Blue="255" />\n' +
  '\t\t\t<LinkedObjectName />\n' +
  '\t\t\t<Rotation>Rotation0</Rotation>\n' +
  '\t\t\t<IsMirrored>False</IsMirrored>\n' +
  '\t\t\t<IsVariable>True</IsVariable>\n' +
  '\t\t\t<GroupID>-1</GroupID>\n' +
  '\t\t\t<IsOutlined>False</IsOutlined>\n' +
  '\t\t\t<HorizontalAlignment>Left</HorizontalAlignment>\n' +
  '\t\t\t<VerticalAlignment>Top</VerticalAlignment>\n' +
  '\t\t\t<TextFitMode>ShrinkToFit</TextFitMode>\n' +
  '\t\t\t<UseFullFontHeight>True</UseFullFontHeight>\n' +
  '\t\t\t<Verticalized>False</Verticalized>\n' +
  '\t\t\t<StyledText>\n' +
  '\t\t\t\t<Element>\n' +
  '\t\t\t\t\t<String xml:space="preserve">Date Time</String>\n' +
  '\t\t\t\t\t<Attributes>\n' +
  '\t\t\t\t\t\t<Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\n' +
  '\t\t\t\t\t</Attributes>\n' +
  '\t\t\t\t</Element>\n' +
  '\t\t\t</StyledText>\n' +
  '\t\t\t<ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>\n' +
  '\t\t\t<BarcodePosition>AboveAddress</BarcodePosition>\n' +
  '\t\t\t<LineFonts>\n' +
  '\t\t\t\t<Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t</LineFonts>\n' +
  '\t\t</AddressObject>\n' +
  '\t\t<Bounds X="352" Y="2160" Width="2880" Height="300" />\n' +
  '\t</ObjectInfo>\n' +
  '\t<ObjectInfo>\n' +
  '\t\t<BarcodeObject>\n' +
  '\t\t\t<Name>lblBarcode</Name>\n' +
  '\t\t\t<ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\n' +
  '\t\t\t<BackColor Alpha="0" Red="255" Green="255" Blue="255" />\n' +
  '\t\t\t<LinkedObjectName />\n' +
  '\t\t\t<Rotation>Rotation0</Rotation>\n' +
  '\t\t\t<IsMirrored>False</IsMirrored>\n' +
  '\t\t\t<IsVariable>False</IsVariable>\n' +
  '\t\t\t<GroupID>-1</GroupID>\n' +
  '\t\t\t<IsOutlined>False</IsOutlined>\n' +
  '\t\t\t<Text>GJESTEBOK12345</Text>\n' +
  '\t\t\t<Type>QRCode</Type>\n' +
  '\t\t\t<Size>Large</Size>\n' +
  '\t\t\t<TextPosition>None</TextPosition>\n' +
  '\t\t\t<TextFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t<CheckSumFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\n' +
  '\t\t\t<TextEmbedding>None</TextEmbedding>\n' +
  '\t\t\t<ECLevel>0</ECLevel>\n' +
  '\t\t\t<HorizontalAlignment>Center</HorizontalAlignment>\n' +
  '\t\t\t<QuietZonesPadding Left="0" Top="0" Right="0" Bottom="0" />\n' +
  '\t\t</BarcodeObject>\n' +
  '\t\t<Bounds X="3600" Y="1440" Width="1152" Height="1152" />\n' +
  '\t</ObjectInfo>\n' +
  '</DieCutLabel>';
