import React, { ReactNode, ReactElement, useContext } from 'react';
import ReactSelect, { components } from 'react-select';
import { Control, Controller } from 'react-hook-form';

// Styles
import { selectComponentStyle, nonSearchableStyle } from './select.styles';

// Components
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-down.svg';

// Context
import { LayoutContext } from 'context/LayoutContext';

export interface SelectOptions {
  label: string | ReactNode | number;
  value: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any): ReactElement => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowIcon className="mr-1" />
    </components.DropdownIndicator>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DisplayError = ({ error }: { error: any }) => (
  <label
    className={`text-red-700 mt-2 ml-2 absolute bottom-0 transform translate-y-full ${
      error && error.message ? 'opacity-100' : 'opacity-0'
    }`}
  >
    {error?.message}
  </label>
);
interface SelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  options: SelectOptions[];
  name: string;
  placeholder: ReactNode | string;
  label: string;
  isSearchable?: boolean;
  required?: boolean;
  loading?: boolean;
  defaultValue?: SelectOptions;
  handleOnChange?: (value: string) => void;
}
export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      control,
      options,
      name,
      isSearchable = false,
      label,
      placeholder,
      required,
      loading,
      defaultValue,
      handleOnChange,
    },
    _ref
  ) => {
    // Context
    const {
      settings: { theme },
    } = useContext(LayoutContext);

    return (
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <div className="mb-6">
            {label && (
              <label
                className="pt-1 text-tertiary-stronger text-xl"
                style={{ color: theme.colors.tertiaryStrong }}
              >
                {label}{' '}
                {required ? <span className="text-black">{' *'}</span> : ''}
              </label>
            )}
            <ReactSelect
              className="max-w-2xl"
              onInputChange={(e) => handleOnChange?.(e)}
              placeholder={placeholder}
              options={options}
              isLoading={loading}
              isSearchable={isSearchable}
              styles={{ ...selectComponentStyle, ...nonSearchableStyle }}
              components={{
                DropdownIndicator: (props) => <DropdownIndicator {...props} />,
                IndicatorSeparator: () => null,
              }}
              onChange={(e) => onChange(e?.value)}
              defaultValue={defaultValue}
            />
            <DisplayError error={error} />
          </div>
        )}
      />
    );
  }
);

export default Select;
