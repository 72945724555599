import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

// Toast
import { toast } from 'react-toastify';

// Context
import { AuthContext } from 'context/AuthContext';
import { LayoutContext } from 'context/LayoutContext';

// Types
import { LoginType } from 'common/types/Login.type';

// Service
import authServices from 'services/auth.service';

// Containers
import { KeyboardInput } from 'containers/KeyboardInput';

// Utils
import { ApiError } from 'utils/api';

// Component
import { Button } from 'common/components/Button';
import { Header } from 'common/components/Header';
import { GlassBoxWrapper } from 'common/components/GlassBoxWrapper';
import Toggle from 'common/components/Toggle';

// Schema
const getSchema = (t: TFunction) =>
  yup.object().shape({
    deviceCode: yup
      .string()
      .required(`${t('errorsFromYup.required.deviceCode')}`),
  });

const Login = () => {
  // State
  const [toggle, setToggle] = useState(false);

  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleLogin } = useContext(AuthContext);
  const { initLoading, finishLoading } = useContext(LayoutContext);

  // Form
  const {
    control,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginType>({
    mode: 'onChange',
    resolver: yupResolver(getSchema(t)),
    defaultValues: {
      deviceCode: '',
    },
  });

  // Handlers
  const handleOnSubmit = async ({ deviceCode }: LoginType) => {
    initLoading();
    try {
      const res = await authServices.login(deviceCode);
      handleLogin({ ...res, deviceCode });
      toast.success(` ${res.message} !`);
    } catch (error) {
      const err = error as ApiError;
      toast.error(`${err.message} !`);
    }
    finishLoading();
    navigate('/');
  };

  const isTouchDevice = () => {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.maxTouchPoints > 0
    );
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden">
      <GlassBoxWrapper>
        <Header
          title={t('common.welcome')}
          className="text-[2rem] z-50 text-center text-tertiary-stronger"
        />
        <Header
          className="text-[5.3rem] font-medium text-center text-tertiary-stronger font"
          title="Gjestbok's panel"
        />
        <div className="!p-0 !m-0 bg-black h-10"></div>
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="flex mt-10 items-center w-full max-w-[37rem] gap-3 flex-nowrap mb-4"
          autoComplete="off"
        >
          <KeyboardInput
            userPrefersPhysicalKeyboard={toggle}
            control={control}
            disableDoneButton={false}
            {...register('deviceCode')}
            label={t('pages.login.label.deviceCode') || 'Device Code'}
            placeholder={t('pages.login.placeholder.enterDeviceCode')}
            className="!bg-white"
            required
            wrapperClassName="flex-1"
          />
          <Button
            className="z-10 self-end !py-[1.15rem] !px-[3.3rem] disabled:opacity-70"
            round="md"
            type="submit"
            title={t('button.connect')}
            disabled={!isValid}
          />
        </form>
        {isTouchDevice() && (
          <Toggle
            isToggled={toggle}
            onClick={() => setToggle((prev) => !prev)}
          />
        )}
      </GlassBoxWrapper>
    </div>
  );
};

export default Login;
