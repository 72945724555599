import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { CheckinContext } from 'context/CheckinContext';
import { LayoutContext } from 'context/LayoutContext';

// Components
import { AbsoluteSpinner } from 'common/components/Spinners';
import ContentLayout from 'common/layouts/ContentLayout';
import { useNavigate } from 'react-router';
import { CompaniesApiType } from 'common/types/CheckinData.type';

interface Props {
  submitButtonText: string;
  hasPreviousButton: boolean;
}

const Company = ({ hasPreviousButton, submitButtonText }: Props) => {
  // State
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Hooks
  const { data, setCompanyId, incrementStep } = useContext(CheckinContext);
  const { companyId } = data;
  const { settings } = useContext(LayoutContext);
  const { t } = useTranslation();

  // Handlers
  const handleSetFirstCompany = () => {
    if (settings.location.companies?.length === 1 && !data.companyId) {
      setCompanyId(settings.location.companies[0].id);
    }
    setLoading(false);
  };

  const handleChooseCompany = (id: number) => {
    setCompanyId(id);
    incrementStep();
  };

  // Effects
  useEffect(() => {
    setLoading(true);
    handleSetFirstCompany();
  }, []);

  // renderers
  const renderCompany = (company: CompaniesApiType) => (
    <button
      className={
        'max-w-[30rem] min-h-[6rem] flex rounded-none flex-row items-center justify-center px-2 py-3 text-center border-2 border-gray-200 bg-transparent'
      }
      onClick={() => handleChooseCompany(company.id)}
      key={company.id}
    >
      {company.logo_link && (
        <div
          className="overflow-hidden"
          style={{
            maxWidth: !settings?.logoSize ? '70px' : `${settings.logoSize}%`,
          }}
        >
          <img
            src={`${company.logo_link}?img=${company.logo}`}
            alt={company.name}
            className={`max-w-full h-auto object-fill duration-300 ${
              imageLoaded ? 'opacity-1' : 'opacity-0'
            }`}
            onLoad={() => setImageLoaded(true)}
          />
        </div>
      )}
      {!company.logo && (
        <abbr
          title={company.name}
          className="overflow-hidden font-bold no-underline text-2xl text-ellipsis"
        >
          {company.name}
        </abbr>
      )}
    </button>
  );

  return (
    <ContentLayout
      submitButtonText={submitButtonText}
      isValid={!!companyId}
      previous={() => navigate('/')}
      hideNextButton={true}
      hasPreviousButton={hasPreviousButton}
      title={t('company.selectCompany')}
      childrenClassName="gap-2 !flex-row flex-wrap max-w-full"
    >
      {loading && <AbsoluteSpinner show={true} />}
      {settings?.location?.companies
        ? settings.location.companies.map(renderCompany)
        : null}
    </ContentLayout>
  );
};

export default Company;
