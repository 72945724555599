import React, { useContext } from 'react';
import { LayoutContext } from 'context/LayoutContext';

interface CheckboxProps {
  placeholder: string;
  shadow?: boolean;
  checked?: boolean;
  className?: string;
  name?: string;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  label?: string | null | undefined;
  disabled?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      placeholder,
      shadow = false,
      className = '',
      label,
      name,
      required = false,
      errors,
      disabled = false,
      ...props
    },
    ref
  ) => {
    const {
      settings: { theme },
    } = useContext(LayoutContext);

    return (
      <div
        style={{ color: theme.colors.tertiaryStrong }}
        className="flex text-black w-fit flex-0"
      >
        <div className="flex flex-row-reverse gap-2 mt-2">
          <label
            style={{ color: theme.colors.tertiaryStrong }}
            htmlFor={name}
            className="flex gap-1 text-black cursor-pointer text-xl"
          >
            <span className="text-xl  break-words max-w-[37rem] text-left">
              {label}
            </span>
            {required && <span className="text-xl text-black">{' *'}</span>}
          </label>
          <div
            className="flex flex-shrink-0 border-2 rounded outline-none w-7 h-7 border-primary"
            style={{ borderColor: theme.colors.primary }}
          >
            <input
              style={{ color: theme.colors.primary }}
              disabled={disabled}
              id={name}
              name={name}
              ref={ref}
              type="checkbox"
              placeholder={placeholder}
              aria-label={`${label || ''}`}
              className="w-full h-full bg-transparent shadow-none cursor-pointer text-ellipsis text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              {...props}
            />
          </div>
        </div>
        <label
          className={`text-red-700 mt-2 ml-2 absolute bottom-0 transform translate-y-full ${
            errors && errors.message ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {errors?.message}
        </label>
      </div>
    );
  }
);

export default Input;
