import { Navigate, Route, Routes, useNavigate } from 'react-router';
import { QueryClient, QueryClientProvider } from 'react-query';

// Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Config
import { configureAPI } from 'utils/api';

// Context
import { AuthContext } from 'context/AuthContext';
import { useAuthHook } from 'context/useAuthHook';
import { useLayoutHook } from 'context/useLayoutHook';
import { LayoutContext } from 'context/LayoutContext';

// Pages
import { Checkin } from 'pages/Checkin';
import { Checkout } from 'pages/Checkout';
import { Home } from 'pages';
import { ThankYou } from 'pages/ThankYou';
import { Login } from 'pages/Auth/Login';

// Components
import { MainLayout } from 'common/layouts';
import { Helmet } from 'common/components/Helmet';
import { AbsoluteSpinner } from 'common/components/Spinners';
import { useEffect, useState } from 'react';
import { NoSettingsAvailable } from 'pages/NoSettingsAvailable';
import { NoPrinterEnvAvailable } from 'pages/NoPrinterEnvAvailable';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BarcodeReader from 'react-barcode-reader';

import checkoutService from 'services/checkout.service';
import { NoCompanyAvailable } from 'pages/NoCompanyAvailable';

// Utils
// eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/ban-ts-comment
// @ts-ignore
const dymo = window['dymo'];

// Declarations
const queryClient = new QueryClient();
const timeOutDelay = 1000 * 60 * 30;
let timeOutId = setTimeout(() => window.location.reload(), timeOutDelay);
function checkLastAction() {
  clearTimeout(timeOutId);
  timeOutId = setTimeout(() => window.location.reload(), timeOutDelay);
}

const App = () => {
  const navigate = useNavigate();
  const data = useAuthHook();
  const { isAuthenticated, handleLogout, loadingAuth } = data;

  const layout = useLayoutHook({ isAuthenticated });

  // State
  const [printerEnvError, setPrinterEnvError] = useState('');

  // Handlers
  configureAPI({
    token: data.auth?.token,
    onSignOut: handleLogout,
    isNotFound: () => navigate('/404page'),
  });

  // Refresh page on 30 min inactivity
  useEffect(() => {
    window.addEventListener('mousemove', checkLastAction);
    window.addEventListener('touchstart', checkLastAction);
    window.addEventListener('keydown', checkLastAction);

    return () => {
      window.removeEventListener('mousemove', checkLastAction);
      window.removeEventListener('touchstart', checkLastAction);
      window.removeEventListener('keydown', checkLastAction);
    };
  }, []);

  // Init dymo
  const onLoadDymo = (params: {
    errorDetails: string;
    isBrowserSupported: boolean;
    isFrameworkInstalled: boolean;
    isWebServicePresent: boolean;
  }) => {
    setPrinterEnvError(params.errorDetails);
  };

  useEffect(() => {
    if (printerEnvError) navigate('/no-printer-available');
  }, [printerEnvError]);

  useEffect(() => {
    if (layout.settings.isPrintBadge) {
      setTimeout(() => dymo.label.framework.init(onLoadDymo), 1);
    }
  }, [layout]);

  const handleScan = async (data: string) => {
    // eslint-disable-next-line no-console
    console.log('ScanData', data);
    const prefix = layout.settings.barcodePrefix;
    if (data.startsWith(prefix)) {
      const { operation = '', message } =
        await checkoutService.scanAutoCheckInOut(data.trim());
      const checkedIn = operation === 'check_in';
      const checkedOut = operation === 'check_out';
      const error = operation === 'error';

      if (checkedIn) navigate('/thank-you/checkin');
      if (checkedOut) navigate('/thank-you/checkout');
      // eslint-disable-next-line no-console
      if (error) console.error('operation error:', message);
    } else {
      // eslint-disable-next-line no-console
      console.error('prefixError', data);
    }
  };

  const handleScanError = (err: string) => {
    // eslint-disable-next-line no-console
    console.error('scanError', err);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider value={{ ...data }}>
        <LayoutContext.Provider value={{ ...layout }}>
          <ToastContainer
            position="bottom-center"
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            theme="colored"
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <MainLayout>
            <BarcodeReader
              onError={handleScanError}
              onScan={handleScan}
              minLength={2}
              endChar={[]}
            />
            {loadingAuth && <AbsoluteSpinner show={true} />}
            {!isAuthenticated && !loadingAuth && (
              <Routes>
                <Route
                  path="/login"
                  element={
                    <Helmet title="Authentication" component={<Login />} />
                  }
                />
                <Route
                  path="/no-printer-available"
                  element={
                    <NoPrinterEnvAvailable errorDetails={printerEnvError} />
                  }
                />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            )}
            {isAuthenticated && !loadingAuth && (
              <Routes>
                <Route
                  path="/"
                  element={<Helmet title="Home" component={<Home />} />}
                />
                <Route
                  path="/no-settings-available"
                  element={<NoSettingsAvailable />}
                />
                <Route
                  path="/no-company-available"
                  element={<NoCompanyAvailable />}
                />
                <Route
                  path="/no-printer-available"
                  element={
                    <NoPrinterEnvAvailable errorDetails={printerEnvError} />
                  }
                />
                <Route
                  path="/checkin"
                  element={<Helmet title="Check-in" component={<Checkin />} />}
                />
                <Route
                  path="/checkout"
                  element={
                    <Helmet title="Check-out" component={<Checkout />} />
                  }
                />
                <Route
                  path="/thank-you/:action"
                  element={
                    <Helmet title="Thank You" component={<ThankYou />} />
                  }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            )}
          </MainLayout>
        </LayoutContext.Provider>
      </AuthContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
