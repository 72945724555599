// Components
import FrontpageNav from './_FrontpageNav';
import { SelectLanguage } from 'common/components/ChangeLanguage';

export const Navigation = () => {
  return (
    <>
      <div className="flex items-center justify-between px-6 mt-10">
        {/* Logo */}
        <div>
          <FrontpageNav />
        </div>

        <div>
          {/* Language component */}
          <SelectLanguage />
        </div>
      </div>
    </>
  );
};

export default Navigation;
