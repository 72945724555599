import { useTranslation } from 'react-i18next';

// Components
import { Button } from '../Button';
import { AbsoluteSpinner } from '../Spinners';

type Props = {
  hasPreviousButton?: boolean;
  nextTitle: string;
  isValid: boolean;
  hideNextButton?: boolean;
  decrementStep?: () => void;
  previous?: () => void;
  loading?: boolean;
  customNextStep?: () => void;
};

const Footer = ({
  hasPreviousButton = false,
  nextTitle,
  isValid,
  customNextStep,
  decrementStep,
  hideNextButton = false,
  previous,
  loading,
}: Props) => {
  // Hooks
  const { t } = useTranslation();

  return (
    <div className="flex w-full ">
      {hasPreviousButton && (
        <Button
          color="black"
          title={t('button.previous')}
          background="none"
          size="sm"
          disabled={loading}
          onClick={() => {
            decrementStep?.();
            previous?.();
          }}
        />
      )}
      <AbsoluteSpinner show={loading} size="sm" />
      {customNextStep ? (
        <Button
          title={nextTitle}
          round="4xl"
          type="submit"
          background="black"
          disabled={!isValid}
          size="sm"
          className="ml-auto"
          onClick={customNextStep}
        />
      ) : (
        <Button
          title={nextTitle}
          round="4xl"
          type="submit"
          background="black"
          disabled={!isValid}
          size="sm"
          className={`ml-auto ${hideNextButton ? 'hidden' : 'flex'}`}
        />
      )}
    </div>
  );
};

export default Footer;
