import React, { useContext, ReactNode, ReactElement } from 'react';

// Context
import { LayoutContext } from 'context/LayoutContext';

// Components
import { Navigation } from 'containers/Navigation';

// import illustrationPhoto from 'assets/images/illustration.png';
import { useLocation } from 'react-router';
import Copyright from 'containers/Copyright';

interface Props {
  children: ReactNode;
}

export const MainLayout = ({ children }: Props): ReactElement => {
  // Context
  const {
    settings: { backgroundImageLink },
  } = useContext(LayoutContext);
  const location = useLocation();

  const isHome = location.pathname === '/';
  const isLogin = location.pathname === '/login';

  return (
    <div className="flex">
      <div className="relative flex flex-col w-screen h-screen p-0 m-0 border-none">
        {/* Background */}
        <div
          className={`fixed bg-none top-0 bottom-0 left-0 right-0 z-0 flex items-center justify-center w-full h-full overflow-hidden align-middle  ${
            !isLogin && !isHome ? 'bg-white opacity-25' : 'blur-sm'
          }`}
        >
          {backgroundImageLink && (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex content-center justify-center w-full h-full overflow-hidden align-middle">
              <img
                alt="Background"
                className="object-cover object-center w-full h-full"
                src={backgroundImageLink}
              />
            </div>
          )}
        </div>

        {/* Header */}
        <div className={`${isLogin ? 'z-40' : 'z-20'} h-36`}>
          <Navigation />
        </div>

        {/* Body */}
        <div className="z-30 w-full h-full overflow-hidden overflow-y-auto grow">
          {children}
          <Copyright />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
