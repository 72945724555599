// Types
import { AbsoluteSpinner } from 'common/components/Spinners';
import { SelectOptions } from './KeyboardInput';

interface Props {
  options: SelectOptions[];
  loading: boolean;
  onSelect: (value: string, nameOfEmployee: string) => void;
}

export const AutoSuggestion = ({
  options,
  onSelect,
  loading = false,
}: Props) => {
  return (
    <div className="flex overflow-x-auto gap-3 -my-10 px-10 cursor-pointer">
      {loading && (
        <div className="flex justify-center">
          <AbsoluteSpinner show />
        </div>
      )}
      {options.map((item) => {
        return (
          <span
            onClick={() => onSelect(item.value, item.label as string)}
            className="bg-lightGray py-4 px-6"
          >
            <p className="whitespace-nowrap">{item.label}</p>
          </span>
        );
      })}
    </div>
  );
};
