import { useContext, useEffect, useState } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslation } from 'react-i18next';

// Types
import {
  InvitesApiDataType,
  InvitesApiResponseType,
  PersonalInformationDataType,
  PhoneNumberDataType,
} from 'common/types/CheckinData.type';

// Context
import { CheckinContext } from 'context/CheckinContext';
import { LayoutContext } from 'context/LayoutContext';

// Containers
import { KeyboardInput } from 'containers/KeyboardInput';

// Services
import checkinServices from 'services/checkin.service';

// Components
import Invite from 'common/components/Invite';
import ContentLayout from 'common/layouts/ContentLayout';
import { Header } from 'common/components/Header';
import { KeyboardLayout } from 'common/components/Input/KeyboardWrapper';

interface Props {
  submitButtonText: string;
  hasPreviousButton: boolean;
}

export const PhoneNumber = ({ hasPreviousButton, submitButtonText }: Props) => {
  // State
  const [invites, setInvites] = useState<InvitesApiResponseType | null>(null);
  const [invite, setInvite] = useState<InvitesApiDataType | null | undefined>(
    null
  );
  const [_personalInformation, _setPersonalInformation] = useState<
    PersonalInformationDataType | null | undefined
  >();

  // Hooks
  const { t } = useTranslation();
  const [selectedInvite, setSelectedInvite] = useState<null | number>(null);

  // Context
  const {
    data: { phoneNumber, companyId },
    setPhoneNumber,
    inviteOnlyChecking,
    loading,
    decrementStep,
    incrementStep,
  } = useContext(CheckinContext);

  const { countryCodeAndFlag } = useContext(LayoutContext);

  // Schema
  const getSchema = (companyId: number | null) =>
    yup.object().shape({
      countryCode: yup
        .string()
        .required('translate_:errorsFromYup.required.countryCode'),
      phoneNumber: yup
        .string()
        .required()
        .test(
          'phoneNumberTest',
          'translate_:errorsFromYup.notValid.phoneNumber',
          async function (value) {
            let phoneNumberWithCountryCode = `+${countryCodeAndFlag.countryCode}${value}`;
            if (value?.includes('+')) {
              phoneNumberWithCountryCode = value;
            }
            try {
              const res = await checkinServices.getPhoneNumberData(
                phoneNumberWithCountryCode,
                companyId
              );
              setInvites({ invites: res.invites, is_invited: res.is_invited });
              if (res?.visitor_data)
                _setPersonalInformation({
                  companyName: res.visitor_data?.company || '',
                  firstName: res.visitor_data?.first_name || '',
                  lastName: res.visitor_data?.last_name || '',
                  rememberMe: true,
                });
              return true;
            } catch {
              setInvite(null);
              setInvites(null);
              _setPersonalInformation(null);
              return false;
            }
          }
        ),
    });

  // Form
  const {
    register,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<PhoneNumberDataType>({
    mode: 'onChange',
    resolver: yupResolver(getSchema(companyId)),
    defaultValues: phoneNumber || countryCodeAndFlag.countryCode,
  });

  const submitStep = (phoneData: PhoneNumberDataType) => {
    setPhoneNumber(
      {
        ...phoneData,
        countryCode: phoneData.phoneNumber.includes('+')
          ? ''
          : `+${countryCodeAndFlag.countryCode}`,
      },
      _personalInformation ?? undefined
    );
    incrementStep();
  };

  const toggleInvite = (inviteId: number) => {
    if (selectedInvite === inviteId) {
      return setSelectedInvite(null);
    }
    setSelectedInvite(inviteId);
  };

  const submitData = (data: PhoneNumberDataType) => {
    const _data: PhoneNumberDataType = {
      ...data,
      phoneNumberWithCountryCode: `${data.countryCode}${data.phoneNumber}`,
    };
    if (invite) {
      inviteOnlyChecking(_data, invite);
      return;
    }
    submitStep(_data);
  };

  useEffect(() => {
    const filterInvites = invites?.invites.find(
      (invite) => invite.id === selectedInvite
    );

    setInvite(filterInvites);
  }, [selectedInvite]);

  return (
    <form
      onSubmit={handleSubmit(submitData)}
      autoComplete="off"
      className="w-full h-full"
    >
      <ContentLayout
        loading={loading}
        hasPreviousButton={hasPreviousButton}
        submitButtonText={
          selectedInvite ? t('button.checkIn') : submitButtonText
        }
        isValid={isValid}
        title={`${t('phoneNumber.enterPhoneNumber')} ${t('common.checkin')}`}
        decrementStep={decrementStep}
        childrenClassName="max-w-2xl ml-auto mr-auto"
      >
        <KeyboardInput
          control={control}
          disableDoneButton={false}
          hideErrorOnEmptyValue
          submitData={handleSubmit(submitData)}
          size="sm"
          isNumericKeyboard={true}
          {...register('phoneNumber')}
          keyboardLayout={KeyboardLayout.NUMERIC}
          label={t('phoneNumber.title') || 'Phone Number'}
          placeholder=""
          required
        />
        {invites?.invites ? (
          <>
            {invites.invites.length > 1 ? (
              <Header
                className="mt-3 text-left"
                textSize="md"
                title={t('phoneNumber.choseMeeting')}
              />
            ) : null}
            {invites.invites.map((invite) => {
              return (
                <Invite
                  key={invite.id}
                  host={`${invite?.first_name} ${invite?.last_name}`}
                  onClick={() => toggleInvite(invite.id)}
                  timeSlot={invite.arrival}
                  location={invite?.location?.street}
                  hostCompany={invite?.employee_company?.name}
                  isClicked={selectedInvite === invite?.id}
                />
              );
            })}
          </>
        ) : null}
      </ContentLayout>
    </form>
  );
};

export default PhoneNumber;
