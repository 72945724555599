import { LayoutContext } from 'context/LayoutContext';
import { ReactElement, ReactNode, useContext } from 'react';

interface Props {
  children: ReactNode | ReactElement;
}

export const GlassBoxWrapper = ({ children }: Props) => {
  const {
    settings: { backgroundImageLink },
  } = useContext(LayoutContext);

  return (
    <div
      className={`relative max-h-full max-w-[978px] w-11/12 lg:w-10/12 xl:w-8/12 2xl:w-7/12 flex flex-col items-center justify-center px-20 py-24 backdrop-blur-xl ${
        backgroundImageLink ? 'bg-tertiary/80' : 'bg-white'
      } rounded-2xl aspect-[16/11]`}
    >
      {children}
    </div>
  );
};
