import { ReactElement } from 'react';

// Types
import { CurrentStepProps } from 'common/types/Step.type';

const CurrentStep: React.FC<CurrentStepProps> = ({
  steps,
  currentStep,
}: CurrentStepProps): ReactElement => {
  const step = steps.find((item) => item.step === currentStep)?.content;
  return step ? <div className="w-full h-full ">{step}</div> : <></>;
};
export default CurrentStep;
