import { HostsResponseType } from 'common/types/hosts.type';
import BaseService from './base.service';

class HostServices extends BaseService {
  async getHots(
    companyId: number | null,
    searchTerm?: string
  ): Promise<HostsResponseType> {
    const res = await this.axios.get(
      `/receptionist/hosts/${companyId}/${searchTerm}`
    );
    return res.data;
  }

  async getDepartmentHosts(
    companyId: number | null,
    searchTerm?: string
  ): Promise<HostsResponseType> {
    const res = await this.axios.get(
      `/receptionist/department/hosts/${companyId}/${searchTerm}`
    );
    return res.data;
  }
}

const hostServices = new HostServices();
export default hostServices;
