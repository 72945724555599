import React, { useContext, useEffect, useState } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { debounce } from 'lodash';

import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

// Types
import { VisitInformationDataType } from 'common/types/CheckinData.type';

// Context
import { CheckinContext } from 'context/CheckinContext';
import { LayoutContext } from 'context/LayoutContext';

// Services
import hostServices from 'services/hosts.service';

// Containers
import { KeyboardInput } from 'containers/KeyboardInput';

// Components
import Select, { SelectOptions } from 'common/components/Select/Select';
import ContentLayout from 'common/layouts/ContentLayout';
import { AbsoluteSpinner } from 'common/components/Spinners';
import { Header } from 'common/components/Header';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-down.svg';

// Schema
const getSchema = () =>
  yup.object().shape({
    host: yup.string().required('translate_:errorsFromYup.required.host'),
    employee: yup.string().when('host', (host, yup) => {
      if (host === 'employee') {
        return yup.required('translate_:errorsFromYup.required.employee');
      }
    }),
    visitPurpose: yup.string(),
    department: yup.string().when('host', (host, yup) => {
      if (host === 'department') {
        return yup.required('translate_:errorsFromYup.required.department');
      }
    }),
  });
interface Props {
  submitButtonText: string;
  hasPreviousButton: boolean;
}

const VisitInformation = React.forwardRef<HTMLInputElement, Props>(
  ({ submitButtonText, hasPreviousButton }, _ref) => {
    // Hooks
    const { t } = useTranslation();

    // State
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    const debouncedSearch = debounce((value) => {
      setDebouncedSearchTerm(value);
    }, 250);

    useEffect(() => {
      debouncedSearch(searchTerm);
      return debouncedSearch.cancel;
    }, [searchTerm]);

    // Declaration
    const hostOptions = [
      { label: t('visitInformation.visitingEmployee'), value: 'employee' },
      { label: t('visitInformation.visitingDepartment'), value: 'department' },
    ];

    // Context
    const {
      setVisitInformation,
      submitCheckinData,
      decrementStep,
      loading,
      data,
    } = useContext(CheckinContext);

    const { settings } = useContext(LayoutContext);
    const {
      location: { companies },
    } = settings;
    const defaultCompanyId = companies?.[0]?.id || null;
    const companyId = data?.companyId || defaultCompanyId;
    const isHostOnly = settings?.hostDepartmentSelector === 1;
    const isDepartmentOnly = settings?.hostDepartmentSelector === 2;
    const isHostAndDepartment = settings?.hostDepartmentSelector === 0;

    const { visitInformation } = data;

    // Form
    const {
      register,
      control,
      getValues,
      watch,
      handleSubmit,
      formState: { isValid },
    } = useForm<VisitInformationDataType>({
      mode: 'onChange',
      resolver: yupResolver(getSchema()),
      defaultValues: {
        ...visitInformation,
        ...(!visitInformation.host && { host: hostOptions[0].value }),
      },
    });

    const visitingHost = watch('host');
    const isVisitingEmployee = visitingHost === 'employee';

    // Queries
    const { data: hostsData, isLoading: hostsLoading } = useQuery(
      ['host', debouncedSearchTerm],
      () => hostServices.getHots(companyId, debouncedSearchTerm)
    );
    const { data: hostsDepartmentData, isLoading: departmentsLoading } =
      useQuery(['departments', debouncedSearchTerm], () =>
        hostServices.getDepartmentHosts(companyId, debouncedSearchTerm)
      );

    // Data variables
    const employeeOptions: SelectOptions[] = hostsData?.data.map(
      ({ text, value }) => ({
        label: text,
        value: value.toString(),
      })
    ) || [{ label: '', value: '' }];

    const departmentOptions: SelectOptions[] = hostsDepartmentData?.data.map(
      ({ text, value }) => ({
        label: text,
        value: value.toString(),
      })
    ) || [{ label: '', value: '' }];

    const next = async (visitData: VisitInformationDataType) => {
      setVisitInformation(visitData);
      await submitCheckinData({
        ...data,
        visitInformation: visitData,
      });
    };

    const previous = () => {
      setVisitInformation(getValues());
      decrementStep();
    };

    const isTouchDevice = () => {
      if (settings.showTouchKeyboard === 0) return false;
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.maxTouchPoints > 0
      );
    };

    // Renders
    const showHost = isHostOnly || isHostAndDepartment;
    const showDepartment =
      !isVisitingEmployee && (isDepartmentOnly || isHostAndDepartment);
    const showEmployee =
      isHostOnly || (!isDepartmentOnly && isVisitingEmployee);

    return (
      <form
        className="w-full h-full"
        onSubmit={handleSubmit(next)}
        autoComplete="off"
      >
        <ContentLayout
          isValid={isValid}
          submitButtonText={submitButtonText}
          hasPreviousButton={hasPreviousButton}
          title={t('visitInformation.enterVisitInfo')}
          previous={previous}
          childrenClassName="max-w-2xl ml-auto mr-auto md:gap-3 2xl:gap-6"
        >
          <AbsoluteSpinner show={loading} />
          {!companyId && (
            <Header title={t('visitInformation.noCompanyAvailable')} />
          )}
          {companyId && (
            <>
              {showHost && (
                <Select
                  control={control}
                  label={t('visitInformation.label.host')}
                  required={true}
                  {...register('host')}
                  defaultValue={hostOptions[0]}
                  options={hostOptions}
                  name="host"
                  placeholder={t(
                    'visitInformation.placeholder.enterYourHostName'
                  )}
                />
              )}

              {showEmployee && !isTouchDevice() && (
                <Select
                  control={control}
                  loading={hostsLoading}
                  handleOnChange={(e) => setSearchTerm(e)}
                  label={t('visitInformation.label.employee')}
                  required={true}
                  {...register('employee')}
                  isSearchable
                  options={employeeOptions}
                  name="employee"
                  placeholder={t(
                    'visitInformation.placeholder.enterYourHostName'
                  )}
                />
              )}

              {showEmployee && isTouchDevice() && (
                <KeyboardInput
                  {...register('employee')}
                  control={control}
                  getSearchTerm={(e) => setSearchTerm(e)}
                  disableDoneButton={true}
                  loading={hostsLoading}
                  hideErrorOnEmptyValue={true}
                  append={
                    <ArrowIcon className="w-[0.9rem] h-[0.9rem] text-base" />
                  }
                  isSearchableSelect={true}
                  options={employeeOptions}
                  capitalizeOnSpace={true}
                  label={t('visitInformation.label.employee') || 'Last Name'}
                  placeholder={t(
                    'visitInformation.placeholder.enterYourHostName'
                  )}
                  required
                />
              )}

              {showDepartment && !isTouchDevice() && (
                <Select
                  control={control}
                  loading={departmentsLoading}
                  label={t('visitInformation.label.department')}
                  handleOnChange={(e) => setSearchTerm(e)}
                  required={true}
                  isSearchable
                  {...register('department')}
                  options={departmentOptions}
                  name="department"
                  placeholder={t(
                    'visitInformation.placeholder.choseDepartmentForYourVisit'
                  )}
                />
              )}

              {showDepartment && isTouchDevice() && (
                <KeyboardInput
                  {...register('department')}
                  control={control}
                  hideErrorOnEmptyValue={true}
                  getSearchTerm={(e) => setSearchTerm(e)}
                  disableDoneButton={true}
                  loading={departmentsLoading}
                  append={
                    <ArrowIcon className="w-[0.9rem] h-[0.9rem] text-base" />
                  }
                  isSearchableSelect={true}
                  options={departmentOptions}
                  capitalizeOnSpace={true}
                  label={t('visitInformation.label.department')}
                  placeholder={t(
                    'visitInformation.placeholder.choseDepartmentForYourVisit'
                  )}
                  required
                />
              )}
              {/* <KeyboardInput
                control={control}
                {...register('visitPurpose')}
                label={
                  t('visitInformation.label.visitPurpose') || 'Visit Purpose'
                }
                placeholder={t('visitInformation.placeholder.visitXEmployee')}
              /> */}
            </>
          )}
        </ContentLayout>
      </form>
    );
  }
);

export default VisitInformation;
